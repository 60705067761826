import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import { validaCnpj } from "../../services/validaCnpj";
import Veiculos from ".";

export default function VeiculosNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [veiculo, setVeiculo] = useState({
    linha: "",
    veiculoLinhaId: "",
    modelo: "",
    material: "",
    status: "",
    materiais: "",
  });
  const [listaLinha, setListaLinha] = useState([]);
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const userName = storage.get("USUARIO").profile.userName;
  const [statusVeiculo, SetStatusVeiculo] = useState([
    { id: "ativo", nome: "Ativo" },
    { id: "inativo", nome: "Inativo" },
  ]);
  const [listaMateriais, setListaMateriais] = useState([]);
  const [dropSelecionado, setDropSelecionado] = useState("");
  async function fnVeiculosBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`veiculo/` + params.id);

      setVeiculo((prev) => ({
        ...prev,
        linha: response.data.linha,
        modelo: response.data.modelo,
        material: response.data.material,
        status: response.data.status,
        materiais: response.data.materiais,
      }));
      setListaMateriais(response.data.materiais);
      setDropSelecionado(response.data?.materiais[0]);
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnVeiculoNovo() {
    try {
      var objEnvio = {
        linha: veiculo.linha,
        veiculoLinhaId: veiculo.veiculoLinhaId,
        modelo: veiculo.modelo,
        material: veiculo.material,
        status: veiculo.status,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`veiculo`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Veículo cadastrado com Sucesso");
        navigate("/gestao-de-usuarios/veiculos");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListaLinhas() {
    var objEnvio = {};
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`veiculo/linha`);
      setListaLinha(response?.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnVeiculoEditar() {
    try {
      var objEnvio = {
        linha: veiculo.linha,
        modelo: veiculo.modelo,
        material: dropSelecionado,
        status: veiculo.status,
      };
      setLoad(true);
      let response = await ApiServices.apiPut(`veiculo/` + params.id, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Veículo alterado com Sucesso");
        navigate("/gestao-de-usuarios/veiculos");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListaLinhas();
    if (params.id) {
      fnVeiculosBuscar();
    }
  }, []);

  useEffect(() => {
    console.log("111111111", dropSelecionado);
  }, [dropSelecionado]);
  useEffect(() => {
    const index = listaMateriais.findIndex(
      (material) => material.materialId === dropSelecionado.materialId
    );

    if (index !== -1) {
      const novaListaMateriais = [...listaMateriais]; // Clonando a lista de materiais
      novaListaMateriais[index] = {
        ...novaListaMateriais[index],
        nome: dropSelecionado.nome,
      }; // Editando o nome do material na posição do índice encontrado
      setListaMateriais(novaListaMateriais); // Atualizando o estado com a lista modificada
    } else {
      console.error("Material não encontrado na lista.");
    }
  }, [dropSelecionado]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR VEÍCULO" : "CADASTRO DE VEÍCULO"}
      </div>
      <div className="subTituloPagina">Dados cadastrais</div>
      <div className="row">
        {/* <div className="col-md-6 mt-3">
          <label>
            Linha
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <select
            className="form-select"
            value={veiculo?.veiculoLinhaId || ""}
            onChange={(e) => {
              e.persist();

              listaLinha.map((ee, ii) => {
                if (e.target.value === ee.veiculoLinhaId) {
                  setVeiculo((prev) => ({
                    ...prev,
                    ...ee,
                    linha: ee.nome,
                  }));
                }
              });
            }}
          >
            <option value="">Selecione</option>
            {listaLinha.map((e, i) => (
              <option key={i} value={e.veiculoLinhaId}>
                {e.nome}
              </option>
            ))}
          </select>
          <div className="avisoCampo">
            {veiculo.linha == null ? "Inserir linha" : " "}
          </div>
        </div> */}

        <div className="col-md-6 mt-3">
          <label>
            Linha
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          {veiculo.linha === null || veiculo.linha === "" ? (
            <select
              className="form-select"
              value={veiculo?.veiculoLinhaId || ""}
              onChange={(e) => {
                e.persist();

                listaLinha.map((ee, ii) => {
                  if (e.target.value === ee.veiculoLinhaId) {
                    setVeiculo((prev) => ({
                      ...prev,
                      ...ee,
                      linha: ee.nome,
                    }));
                  }
                });
              }}
            >
              <option value="">Selecione</option>
              {listaLinha.map((e, i) => (
                <option key={i} value={e.veiculoLinhaId}>
                  {e.nome}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="form-select"
              value={veiculo.linha.nome}
              onChange={(e) => {
                e.persist();

                listaLinha.map((ee, ii) => {
                  if (e.target.value === ee.veiculoLinhaId) {
                    setVeiculo((prev) => ({
                      ...prev,
                      ...ee,
                      linha: ee.nome,
                    }));
                  }
                });
              }}
            >
              {listaLinha.map((e, i) => (
                <option key={i} value={e.nome}>
                  {e.nome}
                </option>
              ))}
            </select>
          )}
          <div className="avisoCampo">
            {veiculo.linha === null || veiculo.linha === ""
              ? "Inserir linha"
              : " "}
          </div>
        </div>

        {/* <div className="col-md-6 mt-3">
          <label>
            Order HMB: Product
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled={params.id}
              onChange={(e) => {
                e.persist();
                setVeiculo((prev) => ({
                  ...prev,
                  modelo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {veiculo.modelo.length < 3 ? "Inserir Order HMB: Product" : " "}
          </div>
        </div> */}
        <div className="col-md-6 mt-3">
          <label>
            Order HMB: Product
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          {veiculo.modelo === null || veiculo.modelo === "" ? (
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                disabled={params.id}
                value={veiculo.modelo || ""}
                onChange={(e) => {
                  e.persist();
                  setVeiculo((prev) => ({
                    ...prev,
                    modelo: e.target.value,
                  }));
                }}
              />
            </form>
          ) : (
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                value={veiculo.modelo.nome}
                onChange={(e) => {
                  e.persist();
                  setVeiculo((prev) => ({
                    ...prev,
                    modelo: e.target.value,
                  }));
                }}
              />
            </form>
          )}
          <div className="avisoCampo">
            {veiculo.modelo === null || veiculo.modelo === ""
              ? "Inserir Order HMB: Product"
              : " "}
          </div>
        </div>
      </div>

      <div className="row">
        {/* <div className="col-md-6 mt-3">
          <label>
            Order HMB: Material
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={veiculo.material}
              onChange={(e) => {
                e.persist();
                setVeiculo((prev) => ({
                  ...prev,
                  material: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {veiculo.material.length < 3 ? "Inserir Order HMB: Material" : " "}
          </div>
        </div> */}
        {/* <div className="col-md-6 mt-3">
          <label>
            Order HMB: Material
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          {!params.id && (veiculo.material === null || veiculo.material === "") ? (
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                value={veiculo.material || ""}
                onChange={(e) => {
                  e.persist();
                  setVeiculo((prev) => ({
                    ...prev,
                    material: e.target.value,
                  }));
                }}
              />
            </form>
          ) : (
            <div className="pointer">
              <select
                className="form-select"
                value={veiculo.materiais.nome}
                onChange={(e) => {
                  setVeiculo((prev) => ({
                    ...prev,
                    material: e.target.value,
                  }));
                  listaMateriais.map((ee, ii) => {
                    console.log("aaa");
                    if (e.target.value == ee.nome) {
                      console.log("bbbaaa");
                      setDropSelecionado(ee);
                    }
                  });
                }}
              >
                {console.log(listaMateriais)}
                {listaMateriais.map((e, i) => (
                  <option value={e.nome} key={i}>
                    {e.nome}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="avisoCampo">
            {veiculo.material === null || veiculo.material === ""
              ? "Inserir Order HMB: Material"
              : " "}
          </div>
        </div> */}
        <div className="col-md-6 mt-3">
          <label>
            Order HMB: Material
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          {params.id ? (
            <div className="pointer">
              <select
                className="form-select"
                value={veiculo.material}
                onChange={(e) => {
                  setVeiculo((prev) => ({
                    ...prev,
                    material: e.target.value,
                  }));
                  listaMateriais.forEach((item) => {
                    if (e.target.value === item.nome) {
                      setDropSelecionado(item);
                    }
                  });
                }}
              >
                {listaMateriais.map((e, i) => (
                  <option value={e.nome} key={i}>
                    {e.nome}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                value={veiculo.material || ""}
                onChange={(e) => {
                  e.persist();
                  setVeiculo((prev) => ({
                    ...prev,
                    material: e.target.value,
                  }));
                }}
              />
            </form>
          )}
          <div className="avisoCampo">
            {veiculo.material === null || veiculo.material === ""
              ? "Inserir Order HMB: Material"
              : " "}
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={veiculo.status}
            onChange={(e) => {
              e.persist();
              setVeiculo((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            <option value={"Ativo"}>Ativo</option>
            <option value={"Inativo"}>Inativo</option>
          </select>
        </div>
      </div>
      {params.id && dropSelecionado != "" && (
        <div className="row">
          <div className="col-md-6">
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                value={dropSelecionado?.nome || ""}
                onChange={(e) => {
                  e.persist();
                  setDropSelecionado((prev) => ({
                    ...prev,
                    nome: e.target.value,
                  }));
                  setVeiculo((prev) => ({
                    ...prev,
                    material: e.target.value,
                  }));
                }}
              />
            </form>
          </div>
        </div>
      )}
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={params.id ? fnVeiculoEditar : fnVeiculoNovo}
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
