import React from "react";
import Swiper from "swiper";
import "./index.css";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";
import Logo from "../../assets/images/png/dev.png";

export default function PrimeiroAcesso() {
  const navigate = useNavigate();
  const [primeiroAcesso, setPrimeirioAcesso] = useState({
    userName: "31438511043",
  });

  async function fnPrimeiroAcesso() {
    try {
      let response = await ApiServices.apiGet(
        "pre-cadastro?userName=" + primeiroAcesso.userName
      );

      if (response?.statusCode == 200) {
        storage.del("USUARIO");
        storage.set("USUARIO", response?.data);
        navigate("/cadastro");
      } else {
        toast.error(response?.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }

  return (
    <>
      <div className="col-md-6 centroVertical">
        <div className="cardPrimeiroAcesso">
          <div className="w-100">
            <img className="mb-3" width={"100%"} src={Logo} alt="" />
            <div className="card-body">
              <form autoComplete="off">
                <label>E-mail</label>
                <input
                  autoComplete="off"
                  className="form-control mb-2"
                  type="text"
                  value={primeiroAcesso.userName}
                  onChange={(e) => {
                    setPrimeirioAcesso({ userName: e.target.value });
                  }}
                />
              </form>

              <button
                className="btn btn-primary w-100 my-2"
                onClick={fnPrimeiroAcesso}
              >
                PRÉ CADASTRO
              </button>
              <button
                className="btn btn-outline-primary w-100 my-2"
                onClick={() => navigate("/")}
              >
                VOLTAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
