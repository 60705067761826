import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import { validaEmail } from "../../services/validaEmail";
import { validaCpf } from "../../services/validaCpf";
import { validaSenha } from "../../services/validaSenha";
import moment, { invalid } from "moment";

export default function ParticipantesNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [checkDataFimAtivo, setCheckDataFimAtivo] = useState(false);
  const [listaEstado, setListaEstado] = useState([]);
  const [idCidadeTemporario, setIdCidadeTemporario] = useState("");
  const [listaCargo, setListaCargo] = useState([]);
  const [listaConcessionaria, setListaConcessionaria] = useState([]);
  const [listaCidade, setListaCidade] = useState([]);
  const [participantes, setParticipantes] = useState({
    nome: "",
    cpf: "",
    codigoLogin: "",
    celular: "",
    email: "",
    concessionaria: "",
    status: "",
    senha: "",
    confirmarSenha: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    estado: "",
    cidade: "",
    cnpj: "",
    razaoSocial: "",
    cargo: "",
    dataFimAtivo: "",
    userDealerHistory: [],
  });
  const { setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();

  async function fnAtivaInativa(status) {
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        `usuario/` +
          params.id +
          "/" +
          (status == "false" ? "inativar" : status == "true" ? "ativar" : "Pré-cadastro")
      );
      toast.success("Participante alterado com sucesso");
      navigate("/gestao-de-usuarios/participantes");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListaCargo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`role/participante`);
      setListaCargo(response?.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListaConcessionarias() {
    var objEnvio = {};
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`dealer`, objEnvio);
      const sortedConcessionarias = response?.data.sort((a, b) => a.codigoDealer - b.codigoDealer);
      setListaConcessionaria(sortedConcessionarias);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnParticipanteBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`usuario/` + params.id);

      setParticipantes((prev) => ({
        ...prev,
        nome: response.data?.nome,
        cpf: response.data?.userName,
        codigoLogin: response.data?.codigoLogin,
        celular: response.data?.phoneNumber,
        email: response.data?.email,
        status: response.data?.status === "Inativo" ? "false" : response.data?.status === "Ativo" ? "true" : "Pré-cadastro",
        concessionaria: response.data?.dealer?.dealerId,
        cep: response.data?.cep1,
        logradouro: response.data?.logradouro1,
        numero: response.data?.numero1,
        complemento: response.data?.complemento1,
        bairro: response.data?.bairro1,
        estado: response.data?.uf1?.ufId,
        cidade: response.data?.cidade1?.cidadeId,
        cargo: response.data?.role?.roleId,
        cadastroCompleto: response.data.cadastroCompleto,
        dataFimAtivo: response.data?.dataFimAtivo
          ? moment(response.data?.dataFimAtivo).format("YYYY-MM-DD")
          : null,
        dataNascimento: response.data?.dataNascimento
          ? moment(response.data?.dataNascimento).format("YYYY-MM-DD")
          : null,
        saldoPontos: response.data.saldoPontos,
        pontosCreditados: response.data.pontosCreditados,
        userDealerHistory: response.data.userDealerHistory,
      }));

      fnPontosExtrato(response.data?.userName);
      fnPontosBalance(response.data?.userName);
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnParticipantesNovo() {
    try {
      var objEnvio = {
        nome: participantes.nome,
        email: participantes.email,
        codigoLogin: participantes.codigoLogin,
        phoneNumber: participantes.celular,
        cep1: participantes.cep || null,
        cidade1: participantes.cidade
          ? {
              cidadeId: participantes.cidade,
            }
          : null,
        bairro1: participantes.bairro || null,
        logradouro1: participantes.logradouro || null,
        numero1: participantes.numero || null,
        complemento1: participantes.complemento || null,
        mesmoEnderecoEntrega: true,
        cep2: null,
        cidade2: null,
        bairro2: null,
        logradouro2: null,
        numero2: null,
        complemento2: null,
        dealer: {
          dealerId: participantes.concessionaria,
        },
        roles: {
          roleId: participantes.cargo,
        },
        userName: participantes.cpf,
        senha: participantes.senha,
        dataFimAtivo: participantes.dataFimAtivo,
        dataNascimento: participantes.dataNascimento,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`participante`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Participante cadastrado com Sucesso");
        navigate("/gestao-de-usuarios/participantes");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnParticipanteEditar() {
    try {
      var objEnvio = {
        nome: participantes.nome,
        email: participantes.email,
        codigoLogin: participantes.codigoLogin,
        phoneNumber: participantes.celular,
        cep1: participantes.cep || null,
        cidade1: participantes.cidade
          ? {
              cidadeId: participantes.cidade,
            }
          : null,
        bairro1: participantes.bairro || null,
        logradouro1: participantes.logradouro || null,
        numero1: participantes.numero || null,
        complemento1: participantes.complemento || null,
        mesmoEnderecoEntrega: true,
        cep2: null,
        cidade2: null,
        bairro2: null,
        logradouro2: null,
        numero2: null,
        complemento2: null,
        dealer: {
          dealerId: participantes.concessionaria,
        },
        roles: {
          roleId: participantes.cargo,
        },
        userName: participantes.cpf,
        dataFimAtivo: participantes.dataFimAtivo,
        dataNascimento: participantes.dataNascimento,
      };

      if (participantes.senha) {
        objEnvio.senha = participantes.senha;
      }

      setLoad(true);
      let response = await ApiServices.apiPut(
        `participante/` + params.id,
        objEnvio
      );

      if (response.statusCode == 200) {
        fnAtivaInativa(participantes.status);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCep(id) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGetEndereco("cep/" + id);
      if (response.uf === null) {
        toast.warn("CEP não encontrado");
      }
      setParticipantes((prev) => ({
        ...prev,
        estado: response?.uf?.ufId,
        logradouro: response?.logradouro,
        bairro: response?.bairro,
      }));
      setIdCidadeTemporario(response?.cidade?.cidadeId);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnEstados() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGetEndereco("uf");
      setListaEstado(response);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCidade(id) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGetEndereco("/cidade/" + id);
      setListaCidade(response);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPontosExtrato(cpf) {
    try {
      var objEnvio = {
        username: cpf,
      };

      setLoad(true);
      const response = await ApiServices.apiPostCatalogo(
        "extractParticipant",
        objEnvio
      );

      if (response) {
        setParticipantes((prev) => ({
          ...prev,
          pontosCreditados: response.creditBalance,
        }));
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPontosBalance(cpf) {
    try {
      var objEnvio = {
        userName: cpf,
      };

      setLoad(true);
      const response = await ApiServices.apiPostCatalogo(
        "balanceParticipant",
        objEnvio
      );
      console.log("response",response )
      if (response) {
        setParticipantes((prev) => ({
          ...prev,
          saldoPontos: response.balance,
        }));
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (idCidadeTemporario) {
      setParticipantes((prev) => ({
        ...prev,
        cidade: idCidadeTemporario,
      }));
    }
  }, [listaCidade, idCidadeTemporario]);
  useEffect(() => {
    if (participantes.estado) {
      fnCidade(participantes.estado);
    }
  }, [participantes.estado]);

  useEffect(() => {
    if (participantes.cep?.length === 8) {
      fnCep(participantes.cep);
    }
  }, [participantes.cep]);

  useEffect(() => {
    if (listaEstado) {
      fnEstados();
    }
  }, []);
  useEffect(() => {
    fnListaConcessionarias();
    if (params.id) {
      fnParticipanteBuscar();
    }
    fnListaCargo();
  }, []);
  useEffect(() => {
    listaConcessionaria.find((e, i) => {
      if (e.dealerId === participantes.concessionaria) {
        setParticipantes((prev) => ({
          ...prev,
          cnpj: e.cnpj,
          razaoSocial: e.razaoSocial,
        }));
      }
    });
  }, [participantes.concessionaria]);
  useEffect(() => {
    if (participantes.dataFimAtivo != null) {
      setCheckDataFimAtivo(true);
    } else {
      setCheckDataFimAtivo(false);
    }
  }, [participantes.dataFimAtivo]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR PARTICIPANTE" : "CADASTRO DE PARTICIPANTE"}
      </div>
      <div className="subTituloPagina">Dados pessoais</div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>
              Nome<span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={participantes.nome}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  nome: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {participantes.nome?.length < 3 ? "Inserir nome" : " "}
          </div>
        </div>

        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>
              CPF (Username)
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled={params.id}
              placeholder="000.000.000-00"
              value={Mask.cpf(participantes.cpf) || ""}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  cpf: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!validaCpf(participantes.cpf) ? "Inserir CPF valido" : " "}
          </div>
        </div>

        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>
              Código Login
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={participantes.codigoLogin}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  codigoLogin: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {participantes.codigoLogin?.length < 3
              ? "Inserir código login"
              : " "}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Celular</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="(00) 00000-0000"
              value={Mask.cel(participantes.celular) || ""}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  celular: Mask.unMask(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {participantes.celular?.length < 11 && participantes.celular?.length
              ? "Inserir celular valido"
              : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>
              E-mail<span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled={params.id}
              value={participantes.email || ""}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!validaEmail(participantes.email) ? "Inserir e-mail valido" : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <select
            className="form-select"
            value={participantes.status || ""}
            onChange={(e) => {
              e.persist();
              setParticipantes((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="Pré-cadastro">Pré-Cadastro</option>
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-3">
          <div className="d-flex mb-2">
            <input
              type="checkbox"
              className="me-2"
              checked={checkDataFimAtivo}
              onChange={() => {
                setCheckDataFimAtivo(checkDataFimAtivo ? false : true);
                setParticipantes((prev) => ({
                  ...prev,
                  dataFimAtivo: null,
                }));
              }}
            />

            <label>Ativo até</label>
          </div>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="date"
              disabled={checkDataFimAtivo == false}
              value={participantes.dataFimAtivo || ""}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  dataFimAtivo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {participantes.dataFimAtivo == null && checkDataFimAtivo == true
              ? "Inserir uma data"
              : " "}
          </div>
        </div>

        <div className="col-md-2 mt-3">
          <form autoComplete="off">
            <label>Data de nascimento</label>
            <input
              autoComplete="off"
              className="form-control"
              type="date"
              value={participantes.dataNascimento || ""}
              onChange={(e) => {
                e.persist();
                setParticipantes((prev) => ({
                  ...prev,
                  dataNascimento: e.target.value,
                }));
              }}
            />
          </form>
        </div>

        <div className="col-md-2 mt-3">
          <form autoComplete="off">
            <label>Total de pontos creditados</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled
              value={
                participantes.pontosCreditados?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                }) || ""
              }
            />
          </form>
        </div>
        <div className="col-md-2 mt-3">
          <form autoComplete="off">
            <label>Saldo de pontos</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled
              value={
                participantes.saldoPontos?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                }) || ""
              }
            />
          </form>
        </div>
      </div>
      <div className="subTituloPagina">Empresa</div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <label>
            Concessionária
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <select
            className="form-select"
            value={participantes.concessionaria || ""}
            onChange={(e) => {
              e.persist();
              setParticipantes((prev) => ({
                ...prev,
                concessionaria: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {listaConcessionaria.map((e, i) => (
              <option key={i} value={e.dealerId}>
                {e.codigoDealer + " " + (e.nomeFantasia || "sem nome")}
              </option>
            ))}
          </select>
          <div className="avisoCampo">
            {!participantes.concessionaria ? "Selecionar concessionaria" : " "}
          </div>
        </div>
        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>CNPJ</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled
              value={Mask.cnpj(participantes?.cnpj || "")}
            />
          </form>
        </div>
        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>Razão Social</label>
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={participantes?.razaoSocial || ""}
            />
          </form>
        </div>
        <div className="col-md-3 mt-3">
          <label>
            Cargo<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <select
            className="form-select"
            value={participantes.cargo || ""}
            onChange={(e) => {
              e.persist();
              setParticipantes((prev) => ({
                ...prev,
                cargo: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {listaCargo.map((e, i) => (
              <option key={i} value={e.roleId}>
                {e.displayName}
              </option>
            ))}
          </select>
          <div className="avisoCampo">
            {!participantes.cargo ? "Selecionar cargo" : " "}
          </div>
        </div>
      </div>
      {participantes?.userDealerHistory?.length > 0 && (
        <div className="subTituloPagina">Histórico de concessionárias</div>
      )}

      {participantes?.userDealerHistory?.map((item) => {
        return (
          <div className="row" key={item.concessionaria}>
            <div className="col-md-2 mt-3">
              <label>De</label>
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                disabled
                value={moment(item.dateFrom).format("DD/MM/YYYY")}
              />
            </div>
            <div className="col-md-2 mt-3">
              <label>Até</label>
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                disabled
                value={moment(item.dateTo).format("DD/MM/YYYY")}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label>Concessionária</label>
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                disabled
                value={item?.concessionaria}
              />
            </div>
            <div className="col-md-2 mt-3">
              <label>CNPJ</label>
              <input
                autoComplete="off"
                className="form-control"
                type="text"
                disabled
                value={Mask.cnpj(item.cnpj || "")}
              />
            </div>
            <div className="col-md-3 mt-3">
              <label>Razão Social</label>
              <input
                disabled
                autoComplete="off"
                className="form-control"
                type="text"
                value={item?.razaoSocial || ""}
              />
            </div>
          </div>
        );
      })}

      <div className="subTituloPagina">Endereço para correspondência</div>

      <div className="row">
        <div className="col-md-4 mt-3">
          <label>CEP</label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control "
              type="text"
              placeholder="00000-000"
              value={Mask.cep(participantes.cep) || ""}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  cep: Mask.unMask(e.target.value),
                }));
              }}
            />
          </form>
        </div>
        <div className="col-md-4 mt-3">
          <label>Logradouro</label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control "
              type="text"
              value={participantes.logradouro || ""}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  logradouro: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        <div className="col-md-4 mt-3">
          <label>Número</label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control "
              type="text"
              value={participantes.numero || ""}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  numero: e.target.value,
                }));
              }}
            />
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>Complemento</label>
            <input
              autoComplete="off"
              className="form-control "
              type="text"
              value={participantes.complemento || ""}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  complemento: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>Bairro</label>
            <input
              autoComplete="off"
              className="form-control "
              type="text"
              value={participantes.bairro || ""}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  bairro: e.target.value,
                }));
              }}
            />
          </form>
        </div>

        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>Estado</label>

            <select
              className="form-control "
              value={participantes.estado || "0"}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  estado: e.target.value,
                }));
              }}
            >
              <option value="0">Selecione</option>
              {listaEstado?.map((e, i) => (
                <option key={i} value={e.ufId}>
                  {e.sigla}
                </option>
              ))}
            </select>
          </form>
        </div>

        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>Cidade</label>

            <select
              className="form-control "
              value={participantes?.cidade || "0"}
              onChange={(e) => {
                setParticipantes((prev) => ({
                  ...prev,
                  cidade: e.target.value,
                }));
              }}
            >
              <option value="0">Selecione</option>
              {listaCidade?.map((e, i) => (
                <option key={i} value={e.cidadeId}>
                  {e.nome}
                </option>
              ))}
            </select>
          </form>
        </div>
      </div>

      {participantes.cadastroCompleto && (
        <>
          <div className="subTituloPagina">Configuração de acesso</div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <label>
                Senha
                <span style={{ color: "red", fontWeight: 900 }}>
                  {params.id ? "" : "*"}
                </span>
              </label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  value={participantes.senha || ""}
                  onChange={(e) => {
                    e.persist();
                    setParticipantes((prev) => ({
                      ...prev,
                      senha: e.target.value,
                    }));
                  }}
                />
              </form>
              <div className="avisoCampo">
                {params.id
                  ? !validaSenha(participantes.senha) &&
                    participantes.senha?.length > 0
                    ? "Inserir senha valida"
                    : " "
                  : !validaSenha(participantes.senha)
                  ? "Inserir senha valida"
                  : " "}
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <label>
                Confirmar Senha
                <span style={{ color: "red", fontWeight: 900 }}>
                  {params.id ? "" : "*"}
                </span>
              </label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  value={participantes.confirmarSenha || ""}
                  onChange={(e) => {
                    e.persist();
                    setParticipantes((prev) => ({
                      ...prev,
                      confirmarSenha: e.target.value,
                    }));
                  }}
                />
              </form>
              <div className="avisoCampo">
                <div className="avisoCampo">
                  {params.id
                    ? (!validaSenha(participantes.confirmarSenha) &&
                        participantes.confirmarSenha?.length > 0) ||
                      !(participantes.senha === participantes.confirmarSenha)
                      ? "Inserir confirmação de senha valida"
                      : " "
                    : !validaSenha(participantes.confirmarSenha) ||
                      participantes.senha !== participantes.confirmarSenha
                    ? "Inserir confirmação de senha valida"
                    : " "}
                </div>
              </div>
            </div>
            <ForcaSenha
              senha={participantes.senha}
              confirmarSenha={participantes.confirmarSenha}
              setValidacao={setValidacao}
            />
          </div>
        </>
      )}
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={() => {
              if (params.id) {
                fnParticipanteEditar();
              } else {
                fnParticipantesNovo();
              }
            }}
            disabled={
              (params.id && params.id && !params.id && !validacao) ||
              (participantes.senha.length > 0 && !validacao) ||
              (participantes.confirmarSenha.length > 0 && !validacao) ||
              participantes.nome?.length < 3 ||
              participantes.codigoLogin?.length < 3 ||
              !participantes.concessionaria ||
              !participantes.cargo ||
              !validaEmail(participantes.email) ||
              !validaCpf(participantes.cpf) ||
              (participantes.celular?.length < 11 &&
                participantes.celular?.length)
            }
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
