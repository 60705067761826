import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
import moment from "moment";
import { data } from "dom7";
export default function Relatorios() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [listaTipoRelatorio, setListaTipoRelatorio] = useState([
    { id: 111, nome: "Acessos" },
    { id: 222, nome: "PopUp" },
  ]);
  const [opcoes, setOpcoes] = useState([
    { id: 111, nome: "Diário" },
    { id: 222, nome: "Total" },
  ]);

  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [opcoesCarregadas, setOpcoesCarregadas] = useState(false);
  const [relatorio, setRelatorio] = useState({
    tipoRelatorio: "111",
    opcao: "",
    dataInicio: "",
    dataFim: "",
    nome: "",
  });

  async function fnExportarDiario() {
    const parametros = {
      dataInicio: relatorio.dataInicio || "",
      dataFim: relatorio.dataFim || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `log-acesso/relatorio-diario?${queryString}`,
        "Relatório diario"
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExportarTotal() {
    setLoad(true);
    try {
      let response = await ApiServices.apiDownloadXlsxGET(
        "log-acesso/relatorio-total",
        "Relatorio total"
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExportarPopUp() {
    setLoad(true);
    try {
      let response = await ApiServices.apiDownloadXlsxGET(
        "popup/exportar",
        "Relatorio total"
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExportarDestinatarioPopUp() {
    setLoad(true);
    try {
      let response = await ApiServices.apiDownloadXlsxGET(
        `popup/exportar-destinatarios?busca=${relatorio.opcao.nome}`,
        "Relatorio destinatarios"
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListar() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("popup/opcoes");
      if (response.statusCode == 200) {
        const novaLista = response.data.map((item, index) => ({
          id: index + 1,
          nome: item,
        }));
        setOpcoes((prevLista) => [...prevLista, ...novaLista]);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (!opcoesCarregadas) {
      // Só executa se ainda não carregou as opções
      fnListar();
      setOpcoesCarregadas(true); // Marca como carregado
    }
  }, [opcoesCarregadas]);

  useEffect(() => {
    if (relatorio.opcao.id !== "111") {
      setRelatorio((prev) => ({
        ...prev,
        dataInicio: "",
        dataFim: "",
      }));
    }
  }, [relatorio.opcao.id]);
  return (
    <>
      <div className="row">
        <span className="tituloPagina">RELATÓRIOS</span>

        <div className="row">
          <div className="col-md-2 mt-3">
            <label>Tipo de relatório</label>
            <select
              className="form-select"
              value={relatorio.tipoRelatorio}
              onChange={(e) => {
                e.persist();
                setRelatorio((prev) => ({
                  ...prev,
                  tipoRelatorio: e.target.value,
                }));
              }}
            >
              {/* <option value={""}>Selecione</option> */}
              {listaTipoRelatorio.map((e, i) => (
                <option key={i} value={e.id}>
                  {e.nome || "sem nome"}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Selecione a opção</label>
            <select
              className="form-select"
              value={relatorio.opcao.id || ""}
              onChange={(e) => {
                const selectedOption = opcoes.find(
                  (option) => option.id === parseInt(e.target.value)
                );
                setRelatorio((prev) => ({
                  ...prev,
                  opcao: selectedOption || { id: "", nome: "" },
                }));
              }}
            >
              <option value={""}>Selecione</option>
              {opcoes
                .filter(
                  (e) =>
                    relatorio.tipoRelatorio !== "222" ||
                    (e.id !== 111 && e.id !== 222)
                ) // Filtra as opções quando o tipoRelatorio é PopUp
                .map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.nome}
                  </option>
                ))}
            </select>
          </div>
          {relatorio.tipoRelatorio != "222" && (
            <div className="col-md-2 mt-3">
              <label>Data início</label>
              <form autoComplete="off">
                <input
                  disabled={
                    relatorio.opcao.id != "111" ||
                    relatorio.tipoRelatorio != "111"
                  }
                  autoComplete="off"
                  className="form-control"
                  type="date"
                  value={relatorio.dataInicio}
                  onChange={(e) => {
                    setRelatorio((prev) => ({
                      ...prev,
                      dataInicio: e.target.value,
                    }));
                  }}
                />
                <div className="avisoCampo">
                  {!(
                    relatorio.dataInicio?.length >= 10 ||
                    relatorio.opcao.id != "111"
                  )
                    ? "Inserir data inicio"
                    : " "}
                </div>
              </form>
            </div>
          )}{" "}
          {relatorio.tipoRelatorio != "222" && (
            <div className="col-md-2 mt-3">
              <label>Data fim</label>
              <form autoComplete="off">
                <input
                  disabled={
                    relatorio.opcao.id != "111" ||
                    relatorio.tipoRelatorio != "111"
                  }
                  autoComplete="off"
                  className="form-control"
                  type="date"
                  value={relatorio.dataFim || ""}
                  onChange={(e) => {
                    setRelatorio((prev) => ({
                      ...prev,
                      dataFim: e.target.value,
                    }));
                  }}
                />
                <div className="avisoCampo">
                  {relatorio.dataInicio?.length >= 10 ||
                  relatorio.opcao.id == "111"
                    ? moment(relatorio.dataInicio).add(3, "months") <
                      moment(relatorio.dataFim)
                      ? "Max. até " +
                        moment(relatorio.dataInicio)
                          .add(3, "months")
                          .format("DD/MM/YYYY")
                      : relatorio.dataFim?.length >= 10
                      ? " "
                      : "Inserir data fim"
                    : " "}
                </div>
              </form>
            </div>
          )}
          <div className="col-md-2 mt-3">
            <label> </label>
            <button
              className="btn btn-primary "
              onClick={() => {
                relatorio.opcao.id == "111"
                  ? fnExportarDiario()
                  : relatorio.tipoRelatorio == "222"
                  ? fnExportarDestinatarioPopUp()
                  : fnExportarTotal()
                 
              }}
              disabled={
                moment(relatorio.dataInicio).add(3, "months") <
                  moment(relatorio.dataFim) ||
                (relatorio.opcao.id == "111" &&
                  (relatorio.dataInicio == "" || relatorio.dataFim == "")) ||
                !relatorio.opcao.id
              }
            >
              Exportar
            </button>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12 mt-3 ">
            {nomeArquivo && (
              <div className="d-flex align-items-center">
                <BsFiletypeXlsx style={{ fontSize: 30 }} />
                <div className="ms-2">{nomeArquivo}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
