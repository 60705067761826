import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";
import Mask from "../../services/mask";

export default function Administradores() {
  const [listaAdministradores, setListaAdministradores] = useState([]);

  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const [pesquisar, setPesquisar] = useState("");

  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "userName",
      header: "CPF (Username)",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "email",
      header: "E-mail",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Ações",
      size: 90,
      id: "mrt-custom-actions",
      accessor: (row) => row,
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate(
              "/gestao-de-usuarios/administradores/editar/" +
                row.original.userId
            );
          }}
        >
          <FaRegEdit style={{ fontSize: 20 }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnExtrairAdministradores() {
    const parametros = {
      nome: novoFiltroColuna.nome || "",
      userName: novoFiltroColuna.userName || "",
      email: novoFiltroColuna.email || "",
      status: novoFiltroColuna.status || "",
    };
    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `relatorios/administradores?${queryString}`,
        "cadastroadministradores"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnAdministrador() {
    const parametros = {
      nome: novoFiltroColuna.nome || "",
      userName: novoFiltroColuna.userName || "",
      email: novoFiltroColuna.email || "",
      status: novoFiltroColuna.status || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`administrador?${queryString}`);
      response.data.forEach((e) => {
        e.userName = Mask.cpf(e.userName);
      });
      if (response.statusCode == 200) {
        setListaAdministradores(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const newObject = {};

    filtroColuna.forEach((item) => {
      newObject[item.id] = item.value;
    });
    setNovoFiltroColuna(newObject);
  }, [filtroColuna]);

  useEffect(() => {
    fnAdministrador();
  }, [novoFiltroColuna]);

  return (
    <>
      <div className="row ">
        <span className="tituloPagina">CADASTRO DE ADMINISTRADORES</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/administradores/novo");
            }}
          >
            + | Novo administrador
          </button>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={fnExtrairAdministradores}
          >
            Extrair administradores
          </button>
        </div>
        <div className="subTituloPagina">Administradores cadastrados</div>
      </div>
      <Tabela
        colunas={colunas}
        dados={listaAdministradores}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        setPesquisar={setPesquisar}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />

      {/* <div style={{ width: 2500, height: 2500, backgroundColor: "green" }}>
        ffffffffffff
      </div> */}
    </>
  );
}
