import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import moment from "moment";

export default function ChamadoNovoEditar() {
  const [chamado, setChamado] = useState({});
  const [resposta, setResposta] = useState("");
  const [encerrado, setEncerrado] = useState("");
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const userName = storage.get("USUARIO").profile.userName;
  const [horaTempoReal, setHoraTempoReal] = useState(
    moment().format("HH:mm:ss")
  );

  async function fnChamadoBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`faleconosco/` + params.id);
      setEncerrado(response.data.respostas[1]);
      response.data.respostas = response.data.respostas[0];
      setChamado(response.data);
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnResposta() {
    try {
      var objEnvio = {
        mensagem: resposta,
        participanteId: chamado.respostas.participanteId,
        anexo: null,
        nome: chamado.respostas.nome,
        administrador: true,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(
        `faleconosco/interacao/` + params.id,
        objEnvio
      );
      fnRespostaFinalizada();
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnRespostaFinalizada() {
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        `faleconosco/finalizar/` + params.id
      );

      if (response.statusCode == 200) {
        toast.success("Chamado respondido e encerrado com Sucesso");
        navigate("/fale-conosco/chamado");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current time every second
      setHoraTempoReal(moment().format("HH:mm:ss"));
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (params.id) {
      fnChamadoBuscar();
    }
  }, []);

  return (
    <div className="row">
      <div className="tituloPagina">FALE CONOSCO</div>
      <div className="subTituloPagina">Dados do cliente</div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>Nome</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={chamado.nome || ""}
              onChange={() => {}}
              disabled
            />
          </form>
        </div>

        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>CPF (Username)</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="000.000.000-00"
              value={Mask.cpf(chamado.userName) || ""}
              onChange={() => {}}
              disabled
            />
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <label>Concessionária</label>
          <input
            autoComplete="off"
            className="form-control"
            type="text"
            value={chamado.concessionaria || "Sem Concessionária Cadastrada"}
            onChange={() => {}}
            disabled
          />
        </div>
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>Cargo</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={chamado.cargo || ""}
              onChange={() => {}}
              disabled
            />
          </form>
        </div>
      </div>

      <div className="subTituloPagina">Detalhe do chamado</div>

      <div className="row">
        <div className="col-md-2 mt-3">
          <form autoComplete="off">
            <label>Protocolo</label>
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={chamado.protocolo || ""}
              onChange={() => {}}
            />
          </form>
        </div>
        <div className="col-md-2 mt-3">
          <label>Início</label>
          <form autoComplete="off">
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={moment(chamado.dataHora).format("DD/MM/YYYY") || ""}
              onChange={() => {}}
            />
          </form>
        </div>
        {/* <div className="col-md-2 mt-3">
          <label>Término</label>
          <form autoComplete="off">
          <input
          autoComplete="off"
          className="form-control"
          type="date"
          value={chamado.dataFim}
          onChange={(e) => {
            e.persist();
            setChamado((prev) => ({
              ...prev,
              dataFim: e.target.value,
            }));
          }}
          />
          </form>
        </div> */}
        <div className="col-md-8 mt-3">
          <form autoComplete="off">
            <label>Motivo</label>
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={chamado.assunto || ""}
              onChange={() => {}}
            />
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-3">
          <label>Conteúdo</label>
          <form autoComplete="off">
            <textarea
              disabled
              autoComplete="off"
              className="form-control"
              rows={5}
              placeholder="Descreva aqui o conteúdo do chamado"
              type="text"
              value={chamado.respostas?.mensagem || ""}
              onChange={() => {}}
            />
          </form>
        </div>
      </div>

      <div className="subTituloPagina">Resposta do atendimento</div>

      <div className="row">
        <div className="col-md-2 mt-3">
          <label>Data de retorno</label>
          <form autoComplete="off">
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={
                moment(encerrado?.dataCriacao).format("DD/MM/YYYY") ||
                moment().format("DD/MM/YYYY")
              }
              onChange={() => {}}
            />
          </form>
        </div>
        <div className="col-md-2 mt-3">
          <label>Hora de retorno</label>
          <form autoComplete="off">
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={
                moment(encerrado?.dataCriacao).format("HH:mm:ss") ||
                horaTempoReal ||
                ""
              }
              onChange={() => {}}
            />
          </form>
        </div>
        <div className="col-md-3 mt-3">
          <form autoComplete="off">
            <label>Usuário</label>
            <input
              disabled
              autoComplete="off"
              className="form-control"
              type="text"
              value={"Administrador"}
              onChange={() => {}}
            />
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-3">
          <label>Mensagem*</label>
          <form autoComplete="off">
            <textarea
              autoComplete="off"
              className="form-control"
              rows={5}
              placeholder="Descreva aqui a resposta do chamado"
              type="text"
              value={encerrado?.mensagem || resposta || ""}
              disabled={encerrado?.mensagem}
              onChange={(e) => {
                e.persist();
                setResposta(e.target.value);
              }}
            />
          </form>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>

        {(chamado.status == "EmAberto" || chamado.status == "Pendente") && (
          <div className="col-md-3 mt-2">
            <button
              className="btn btn-primary w-100 "
              onClick={fnResposta}
              disabled={resposta.length < 3}
            >
              ENCERRAR CHAMADO
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
