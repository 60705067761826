import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import Checkbox from "../../Components/Checkbox";
import moment from "moment";

export default function BannerNovoEditar() {
  const [verImagem, setVerImagem] = useState("");
  const [arquivoFile, setArquivoFile] = useState(null);
  // const [visualizarBanner, setVisualizarBanner] = useState([]);
  const [banner, setBanner] = useState({
    titulo: "",
    status: "ativo",
    ordem:"",
    nomeArquivo:"",
    imagemAnexada:"",
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();


  async function fnBannersBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`banner/` + params.id);
      setBanner((prev) => ({
        ...prev,
        titulo: response.data.titulo,
        nomeArquivo:response.data.nomeArquivo,
        status: response.data.ativo ? "ativo" : "inativo",
        ordem: response.data.ordem,
      }));

      setVerImagem(response.data.urlArquivo);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnBannersNovo() {
    try {
      var objEnvio = {
        titulo: banner.titulo,
        ordem: banner.ordem,
        ativo: banner.status == "ativo" ? true : false,
        nomeArquivo:banner.nomeArquivo,
        urlArquivo: banner.imagemAnexada,
        bannerId: params.id,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`banner`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Banner cadastrado");
        navigate("/comunicacao/banner");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnBannersEditar() {
    console.log(arquivoFile)
    var objEnvio = {
      titulo: banner.titulo,
      ordem: banner.ordem,
      ativo: banner.status == "ativo" ? true : false,
      nomeArquivo:banner.nomeArquivo,
      urlArquivo: banner.imagemAnexada || verImagem,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPut(`banner/` + params.id, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Banner alterado");
        navigate("/comunicacao/banner");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnCarregarImagem(e) {
    try {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            if (image.width !== 500 && image.height !== 218) {
              toast.warn("Imagem deve ter 500X218");
            } else {
              setVerImagem(e.target.result);
              setBanner((prev) => ({
                ...prev,
                nomeArquivo: file.name,
               
              }));
              setLoad(false);

              const dataForm = new FormData();
              dataForm.append("file", file);
              console.log('aruivo', image)
              setArquivoFile(dataForm);
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
      }
    } catch (err) {
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }


  async function fnEnviarImagemApi() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(`upload/banner`, arquivoFile);

      setBanner((prev) => ({
        ...prev,
        imagemAnexada: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (banner.imagemAnexada != "") {
      params.id ? fnBannersEditar() : fnBannersNovo();
    }
  }, [banner.imagemAnexada]);

  useEffect(() => {
    params.id && fnBannersBuscar();
  }, []);



  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR BANNER" : "CADASTRO DE BANNER"}
      </div>
      <div className="subTituloPagina">Configuração geral</div>

      <div className="row">
        <div className="col-md-2 mt-3">
          <label>
            Título<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={banner.titulo}
              onChange={(e) => {
                e.persist();
                setBanner((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {banner.titulo.length < 3 ? "Inserir título" : " "}
          </div>
        </div>

        <div className="col-md-2 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={banner.status}
            onChange={(e) => {
              e.persist();
              setBanner((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Ordem<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={banner.ordem}
              onChange={(e) => {
                e.persist();
                setBanner((prev) => ({
                  ...prev,
                  ordem: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {banner?.ordem?.length < 1 ? "Inserir ordem" : " "}
          </div>
        </div>
      </div>

      <div>
        {verImagem ? (
          <button
            className="btn btn-danger my-3"
            style={{ width: 360 }}
            onClick={() => {
              setVerImagem("");
              setArquivoFile(null);
              document.querySelector(".inputUpload").value = "";
            }}
          >
            Excluir arte do banner
          </button>
        ) : (
          <button
            className="btn btn-primary my-3"
            style={{ width: 360 }}
            onClick={() => {
              document.querySelector(".inputUpload").click();
            }}
          >
            Importar arte do banner
          </button>
        )}
        <input
          className="d-none inputUpload"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={fnCarregarImagem}
        />
        <div style={{ fontSize: 12 }}>Formatos aceitos: jpeg, jpg, png</div>
        <div style={{ fontSize: 12 }}>Tamanho recomendado: 500 x 218 px</div>
        <div
          className="border"
          style={{ width: 500, height: 218, overflow: "hidden" }}
        >
          <img src={verImagem} alt="" />
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={fnEnviarImagemApi}
            disabled={banner.titulo.length < 3 || !verImagem || banner.ordem == "" }
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
