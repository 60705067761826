import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import EditorConvertToHTML from "../../Components/TextoHtml/EditorTexto";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Mask from "../../services/mask";
import moment from "moment";
export default function PopUpNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [popUp, setPopUp] = useState({
    titulo: "",
    mensagem: "",
    role: [],
    periodoAtividade: "",
    dataInicio: "",
    dataFim: "",
    popUpComAceite: true,
    status: "ativo",
  });

  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const [periodoAtividade, setPeriodoAtividade] = useState([
    { periodoAtividadeId: "0", nome: "Indeterminado" },
    { periodoAtividadeId: "1", nome: "Determinado" },
  ]);
  const [visualizarPopUp, setVisualizarPopUp] = useState([]);

  async function fnRoleParticipante() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`role/participante`);

      response.data.forEach((e) => {
        e.status = true;
      });
      setVisualizarPopUp(response.data);
      if (params.id) {
        fnPopUpBuscar(response.data);
      }
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPopUpBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`popup/` + params.id);

      setPopUp((prev) => ({
        ...prev,
        titulo: response.data.titulo,
        mensagem: response.data.mensagem,
        status: response.data.status === "Ativo" ? "ativo" : response.data.status === "Inativo" ? "inativo" : "rascunho",
        dataInicio: moment(response.data.dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(response.data.dataFim).format("YYYY-MM-DD"),
        periodoAtividade: response.data.tipo === false ? "0" : "1",
        popUpComAceite: response.data.popUpComAceite,
      }));

      const contentBlock = htmlToDraft(response.data.mensagem);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);

      if (response.statusCode == 200) {
        const popDestinatarios = response.data.popDestinatarios;
        setVisualizarPopUp((prev) =>
          prev.map((role) => ({
            ...role,
            status: popDestinatarios.includes(role.roleId), // Verifique se o roleId está nos destinatários
          }))
        );
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPopUpNovo() {
    try {
      var objEnvio = {
        titulo: popUp.titulo,
        mensagem: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        status: popUp.status,
        popUpComAceite: popUp.popUpComAceite,
        tipo: popUp.periodoAtividade == "0" ? false : true,
        dataInicio: moment(popUp.dataInicio).isValid()
          ? moment(popUp.dataInicio).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        dataFim: moment(popUp.dataFim).isValid()
          ? moment(popUp.dataFim).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        destinatarios: popUp.role,
      };

      setLoad(true);
      console.log(objEnvio);
      let response = await ApiServices.apiPost(`popup`, objEnvio);

      if (response.statusCode == 200) {
        navigate("/comunicacao/pop-up");
        toast.success("Pop-up cadastrado com Sucesso");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnPopUpEditar() {
    try {
      var objEnvio = {
        titulo: popUp.titulo,
        mensagem: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        status: popUp.status,
        popUpComAceite: popUp.popUpComAceite,
        tipo: popUp.periodoAtividade == "0" ? false : true,
        dataInicio: moment(popUp.dataInicio).isValid()
          ? moment(popUp.dataInicio).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        dataFim: moment(popUp.dataFim).isValid()
          ? moment(popUp.dataFim).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        destinatarios: popUp.role,
        // logado: true,
      };

      setLoad(true);
      let response = await ApiServices.apiPut(`popup/` + params.id, objEnvio);

      if (response.statusCode == 200) {
        navigate("/comunicacao/pop-up");
        toast.success("Pop-up alterado com sucesso");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  const handleAceiteChange = (e) => {
    setPopUp({
      ...popUp,
      popUpComAceite: e.target.value === "true",
    });
  };

  useEffect(() => {
    if (params.id) {
      fnPopUpBuscar();
    } else {
      // Resetar visualizarPopUp quando criando um novo
      setVisualizarPopUp((prev) =>
        prev.map((role) => ({ ...role, status: false }))
      );
    }
  }, []);

  useEffect(() => {
    fnRoleParticipante();
  }, []);

  useEffect(() => {
    var temp = [];
    visualizarPopUp.map((e, i) => {
      if (e.status === true) {
        temp.push({
          roleId: e.roleId,
          displayName: e.displayName,
        });
      }
    });
    setPopUp((prev) => ({
      ...prev,
      role: temp,
    }));
  }, [visualizarPopUp]);

  useEffect(() => {
    if (popUp.periodoAtividade == "0") {
      setPopUp((prev) => ({ ...prev, dataInicio: "", dataFim: "" }));
    }
  }, [popUp.periodoAtividade]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR POP-UP" : "CADASTRO DE POP-UP"}
      </div>

      <div className="subTituloPagina">Configuração geral</div>
      <div className="row">
        <div className="col-md-2 mt-3">
          <label>
            Título<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={popUp.titulo}
              onChange={(e) => {
                e.persist();
                setPopUp((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {popUp?.titulo?.length < 3 ? "Inserir título" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Tipo<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={popUp.periodoAtividade}
            onChange={(e) => {
              e.persist();
              setPopUp((prev) => ({
                ...prev,
                periodoAtividade: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {periodoAtividade.map((e, i) => (
              <option value={e.periodoAtividadeId} key={i}>
                {e.nome}
              </option>
            ))}
          </select>
          <div className="avisoCampo">
            {!popUp.periodoAtividade ? "Inserir tipo" : " "}
          </div>
        </div>

        <div className="col-md-2 mt-3">
          <label>
            Início<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="date"
              value={popUp.dataInicio || ""}
              disabled={popUp.periodoAtividade === "0"}
              onChange={(e) => {
                e.persist();
                setPopUp((prev) => ({
                  ...prev,
                  dataInicio: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!popUp.dataInicio && popUp.periodoAtividade != "0"
              ? "Inserir data"
              : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Término<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="date"
              value={popUp.dataFim || ""}
              disabled={popUp.periodoAtividade === "0"}
              onChange={(e) => {
                e.persist();
                setPopUp((prev) => ({
                  ...prev,
                  dataFim: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!popUp.dataFim && popUp.periodoAtividade != "0"
              ? "Inserir data"
              : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={popUp.status}
            onChange={(e) => {
              e.persist();
              setPopUp((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
            <option value="rascunho">Rascunho</option>
          </select>
        </div>
        <div className="col-md-2 mt-3">
          <div>
            <label>
              Visualizar Pop-up
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <button
              className=" form-control d-flex justify-content-between w-100 "
              data-bs-toggle="dropdown"
            >
              <div className=" tresPontinhos d-flex" style={{ width: "80%" }}>
                {visualizarPopUp?.reduce(
                  (count, item) => count + (item.status === true ? 1 : 0),
                  0
                )}
                Selecionados
              </div>
              <div>
                <svg
                  fill="#000000"
                  height="10px"
                  width="10px"
                  viewBox="0 0 330 330"
                >
                  <path
                    id="XMLID_225_"
                    d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                    stroke="#000000"
                    strokeWidth="10"
                  />
                </svg>
              </div>
            </button>
            <div className="dropdown-menu">
              {visualizarPopUp.map((option, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisualizarPopUp((prev) =>
                      prev.map((item, i) =>
                        i === index ? { ...item, status: !item.status } : item
                      )
                    );
                  }}
                >
                  <div className="m-1 ms-3  ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={option?.status}
                      onChange={() => {}}
                    />
                  </div>
                  <label className="m-1 fs-6">
                    {option?.displayName}
                    <span style={{ color: "red", fontWeight: 900 }}>*</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="avisoCampo">
            {!popUp?.role[0] ? "Selecione um perfil" : " "}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2 mt-3">
          <label htmlFor="popUp" style={{ color: "rgb(43 114 162)" }}>
            Pop-up com aceite
          </label>
          <div className="d-flex align-items-center">
            <div className="form-check me-2">
              <input
                type="radio"
                id="aceite-sim"
                name="aceite"
                value="true"
                checked={popUp.popUpComAceite === true}
                onChange={handleAceiteChange}
                className="form-check-input"
              />
              <label htmlFor="aceite-sim" className="form-check-label">
                Sim
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                id="aceite-nao"
                name="aceite"
                value="false"
                checked={popUp.popUpComAceite === false}
                onChange={handleAceiteChange}
                className="form-check-input"
              />
              <label htmlFor="aceite-nao" className="form-check-label">
                Não
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* TEXTO */}
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <label>
              Texto
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <form autoComplete="off">
              <div className="form-control">
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(e) => {
                    setEditorState(e);
                  }}
                />
              </div>
              <div className="avisoCampo">
                {draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  ?.length < 9
                  ? "Inserir texto valido"
                  : " "}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            disabled={
              popUp.titulo?.length < 3 ||
              popUp.periodoAtividade == "" ||
              (!popUp.dataFim && popUp.periodoAtividade != "0") ||
              (!popUp.dataInicio && popUp.periodoAtividade != "0") ||
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
                ?.length < 9
            }
            className="btn btn-primary w-100 "
            onClick={() => {
              if (params.id) {
                fnPopUpEditar();
              } else {
                fnPopUpNovo();
              }
            }}
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
