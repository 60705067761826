import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import EditorConvertToHTML from "../../Components/TextoHtml/EditorTexto";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Mask from "../../services/mask";
export default function FaqNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [faq, setFaq] = useState({
    titulo: "",
    texto: "",
    ordem: "",
    status: "true",
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();

  async function fnFaqBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`faq/` + params.id);
      setFaq((prev) => ({
        ...prev,

        titulo: response.data.titulo,
        texto: response.data.texto,
        ordem: response.data.ordem,
        status: response.data.ativo ? "true" : "false",
      }));

      const contentBlock = htmlToDraft(response.data.texto);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnFaqNovo() {
    try {
      var objEnvio = {
        titulo: faq.titulo,
        texto: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        ordem: faq.ordem,
        ativo: faq.status == "true" ? true : false,
        logado: true,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`faq`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Faq cadastrado com Sucesso");
        navigate("/faq");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnAtivaInativa(status) {
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        `usuario/` +
          params.id +
          "/" +
          (status == "false" ? "inativar" : "ativar")
      );
      toast.success("Faq alterado com sucesso");
      navigate("/faq");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnFaqEditar() {
    try {
      var objEnvio = {
        titulo: faq.titulo,
        texto: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        ordem: faq.ordem,
        ativo: faq.status == "true" ? true : false,
        logado: true,
      };

      setLoad(true);
      let response = await ApiServices.apiPut(`faq/` + params.id, objEnvio);

      if (response.statusCode == 200) {
        fnAtivaInativa(faq.status);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      fnFaqBuscar();
    }
  }, []);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR FAQ" : "CADASTRO DE FAQ"}
      </div>

      <div className="subTituloPagina">Configuração geral</div>
      <div className="row">
        {/* TITULO */}
        <div className="col-md-6 mt-3">
          <label>
            Título
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={faq.titulo}
              onChange={(e) => {
                e.persist();
                setFaq((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!faq.titulo ? "Inserir título valido" : " "}
          </div>
        </div>
        {/* ORDEM */}
        <div className="col-md-3 mt-3">
          <label>
            Ordem<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={faq.ordem}
              onChange={(e) => {
                e.persist();
                setFaq((prev) => ({
                  ...prev,
                  ordem: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {faq.ordem.length < 1 ? "Inserir uma ordem valida" : " "}
          </div>
        </div>
        {/* STATUS */}
        <div className="col-md-3 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={faq.status}
            onChange={(e) => {
              e.persist();
              setFaq((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value={"true"}>Ativo</option>
            <option value={"false"}>Inativo</option>
          </select>
        </div>
      </div>

      {/* TEXTO */}
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <label>
              Texto
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <form autoComplete="off">
              <div className="form-control">
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(e) => {
                    setEditorState(e);
                  }}
                />
              </div>
              <div className="avisoCampo">
                {draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  .length < 9
                  ? "Inserir texto valido"
                  : " "}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            disabled={
              faq.titulo.length < 3 ||
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
                .length < 9 ||
              faq.ordem.length < 1
            }
            className="btn btn-primary w-100 "
            onClick={() => {
              if (params.id) {
                fnFaqEditar();
              } else {
                fnFaqNovo();
              }
            }}
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
