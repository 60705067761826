import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";
import Mask from "../../services/mask";
import moment from "moment";

export default function PopUp() {
  const [listaPopUp, setListaPopUp] = useState([]);

  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const [pesquisar, setPesquisar] = useState("");

  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  async function fnPopUp() {
    const parametros = {
      pesquisa: pesquisar || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `popup?${queryString}`, 
      );
      response.data.forEach((item) => {
        item.status = item.status === "Ativo" ? "Ativo" : item.status === "Inativo" ? "Inativo": 'Rascunho';
        item.dataFim = item.dataFim ? moment(item.dataFim).format('DD/MM/YYYY') : "Indeterminado";
        item.dataInicio = item.dataInicio ? moment(item.dataInicio).format('DD/MM/YYYY') : "Indeterminado";
      });      
      if (response.statusCode == 200) {
        setListaPopUp(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const colunas = [
    {
      accessorKey: "titulo",
      header: "Título",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "dataInicio",
      header: "De",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "dataFim",
      header: "Até",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Ações",
      size: 90,
      id: "mrt-custom-actions",
      accessor: (row) => row,
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate("/comunicacao/pop-up/editar/" + row.original.popUpId);
          }}
        >
          <FaRegEdit style={{ fontSize: 20 }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  useEffect(() => {
    if (!pesquisar) {
      setPesquisar("");
    }
    fnPopUp();
  }, [pesquisar]);


  return (
    <>
      <div className="row ">
        <span className="tituloPagina">CADASTRO DE Pop-up</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/comunicacao/pop-up/novo");
            }}
          >
            + | Novo pop-up
          </button>
        </div>
        <div className="subTituloPagina">Pop-ups cadastrados</div>
      </div>
      <Tabela
        colunas={colunas}
        dados={listaPopUp}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        setPesquisar={setPesquisar}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
        buscaColunas={false}
        buscaGlobal={true}
      />

      {/* <div style={{ width: 2500, height: 2500, backgroundColor: "green" }}>
        ffffffffffff
      </div> */}
    </>
  );
}
