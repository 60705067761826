import React from "react";
import Swiper from "swiper";
import "./index.css";
import Logo from "../../assets/images/png/dev.png";
import Logo2 from "../../assets/images/png/dev2.png";
import Linkedin from "../../assets/images/svg/linkedin.svg";
import Instagram from "../../assets/images/svg/instagram.svg";
import Facebook from "../../assets/images/svg/facebook.svg";
import Youtube from "../../assets/images/svg/youtube.svg";
import Twitter from "../../assets/images/svg/twitter.svg";
import LogoVertem from "../../assets/images/png/logoVertem.png";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import FaqDeslogado from "../FaqDeslogado";
import { useLoad } from "../../context/Load";
import EsqueciSenha from "../EsqueciSenha";
import PrimeiroAcesso from "../PrimeiroAcesso";
import Mask from "../../services/mask";
import { validaCpf } from "../../services/validaCpf";
import { validaEmail } from "../../services/validaEmail";
import { validaSenha } from "../../services/validaSenha";
import RecriarSenha from "../RecriarSenha";

export default function Login() {
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    userName: window.location.hostname === "localhost" ? "45434565568" : "",
    password: window.location.hostname === "localhost" ? "12qw!@QW" : "",
  });
  const { load, setLoad } = useLoad();

  async function fnLogin() {
    localStorage.clear();
    setLoad(true);
    try {
      let response = await ApiServices.apiLogin("login", login);
      const data = await response.json();
      if (data.statusCode === 200) {
        storage.set("USUARIO", data.data);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
      if (storage.get("USUARIO")?.token) {
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  }
  const pathname = () => {
    return window.location.pathname;
  };

  useEffect(() => {
    pathname();
  }, [navigate]);
  return (
    <>
      <div className="row">
        {pathname().includes("/login") && (
          <div className="col-md-6 centroVertical ">
            <div className="cardLogin">
              <img className="mb-3" width={"100%"} src={Logo} alt="" />

              <form autoComplete="off">
                <label>Usuário</label>
                <input
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  value={
                    /\d/g.test(login.userName[0])
                      ? Mask.cpf(login.userName)
                      : login.userName
                  }
                  onChange={(e) => {
                    setLogin((prev) => ({
                      ...prev,
                      userName: Mask.number(e.target.value),
                    }));
                  }}
                />
                <div className="avisoCampo">
                  {(/\d/g.test(login.userName[0])
                    ? !validaCpf(login.userName)
                    : !validaEmail(login.userName)) &&
                  login.userName.length >= 5
                    ? "Formato inválido"
                    : " "}
                </div>
              </form>
              <form autoComplete="off">
                <label>Senha</label>
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  value={login.password}
                  onChange={(e) => {
                    setLogin((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                />
                <div className="avisoCampo">
                  {!validaSenha(login.password) && login.password.length >= 1
                    ? "Formato inválido"
                    : " "}
                </div>
              </form>
              <div className="mb-5 fw-bold mt-2 w-100 text-end">
                <Link to={"/esqueci-senha"}>Esqueci a senha </Link>
              </div>

              <button
                className="btn btn-primary btn-lg w-100 my-2"
                onClick={fnLogin}
                disabled={
                  (/\d/g.test(login.userName[0])
                    ? !validaCpf(login.userName)
                    : !validaEmail(login.userName)) ||
                  !validaSenha(login.password)
                }
              >
                ENTRAR
              </button>
              {/* <button
                className="btn btn-outline-primary w-100 my-2"
                onClick={() => {
                  navigate("/primeiro-acesso");
                }}
              >
                Meu primeiro acesso
              </button> */}
              {/* <div className="mt-3">
                <div className="text-center">Está com dúvidas?</div>
                <div className="text-center">
                  Acesse as
                  <b className="pointer">
                    <Link to="/faq-deslogado"> perguntas frequentes </Link>
                  </b>
                </div>
              </div> */}
            </div>
            <div className="border-top w-100 footerLogin mt-3">
              <div className="mt-2">
                <img className="ms-2" width={150} src={Logo2} alt="" />
              </div>
              <div className="text-center">
                {/* <img className="m-3 logoRede pointer" src={Twitter} alt="" /> */}
                <a href="https://www.youtube.com/@devpartnerbr" target="_blank">
                  <img className="m-3 logoRede pointer" src={Youtube} alt="" />
                </a>
                <a href="https://www.facebook.com/devpartnerbr" target="_blank">
                  <img className="m-3 logoRede pointer" src={Facebook} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/devpartnerbr/"
                  target="_blank"
                >
                  <img
                    className="m-3 logoRede pointer"
                    src={Instagram}
                    alt=""
                  />
                </a>
                <a
                  href="https://br.linkedin.com/company/devpartnerbr"
                  target="_blank"
                >
                  <img className="m-3 logoRede pointer" src={Linkedin} alt="" />
                </a>
              </div>
            </div>
          </div>
        )}

        {pathname().includes("/faq-deslogado") && <FaqDeslogado />}
        {pathname().includes("/esqueci-senha") && <EsqueciSenha />}
        {pathname().includes("/alterar-senha") && <RecriarSenha />}
        {pathname().includes("/primeiro-acesso") && <PrimeiroAcesso />}

        <div className="col-md-6 bgLogin d-none d-md-flex">
          <div className="tituloLogin">{/* ANTE FAUCIBUS ORCI */}</div>
          <div className="subTitulo">
            {/* PELLENTESQUE VEL IPSUM SIT AMET ENIM BIBENDUM INTEGER. */}
          </div>
        </div>
      </div>
    </>
  );
}
