import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import { validaCnpj } from "../../services/validaCnpj";

export default function ConcessionariasNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [concessionaria, setConcessionaria] = useState({
    codigoDealer: "",
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    status: "",
    grupo: "",
    divisao: "",
    regional: "",
    grupoId: "",
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const [listaGrupos, setListaGrupos] = useState([]);
  const [listaDivisao, setListaDivisao] = useState([]);
  const [listaRegional, setListaRegional] = useState([]);
  const userName = storage.get("USUARIO").profile.userName;

  async function fnConcessionariaBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`dealer/` + params.id);

      setConcessionaria((prev) => ({
        ...prev,
        codigoDealer: response.data.codigoDealer,
        cnpj: response.data.cnpj,
        razaoSocial: response.data.razaoSocial,
        nomeFantasia: response.data.nomeFantasia,
        status: response.data.status,
        grupoId: response.data.grupoId,
        grupo: response.data?.grupo,
        divisao: response.data?.divisao,
        regional: response.data?.regionalVendas,
      }));

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnConcessionariaNovo() {
    try {
      var objEnvio = {
        codigoDealer: concessionaria.codigoDealer,
        cnpj: concessionaria.cnpj,
        razaoSocial: concessionaria.razaoSocial,
        nomeFantasia: concessionaria.nomeFantasia,
        status: concessionaria.status == "" ? "Ativo" : concessionaria.status,
        grupoId: concessionaria.grupoId,
        grupo: concessionaria.grupo,
        divisao: concessionaria.divisao,
        regional: concessionaria.regional,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`dealer`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Concessionária cadastrada com Sucesso");
        navigate("/gestao-de-usuarios/concessionarias");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnConcessionariaEditar() {
    try {
      var objEnvio = {
        codigoDealer: concessionaria.codigoDealer,
        cnpj: concessionaria.cnpj,
        razaoSocial: concessionaria.razaoSocial,
        nomeFantasia: concessionaria.nomeFantasia,
        status: concessionaria.status == "" ? "Ativo" : concessionaria.status,
        grupoId: concessionaria.grupoId,
        grupo: concessionaria.grupo,
        divisao: concessionaria.divisao,
        regional: concessionaria.regional,
      };

      setLoad(true);
      let response = await ApiServices.apiPut(`dealer/` + params.id, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Concessionária alterada com Sucesso");
        navigate("/gestao-de-usuarios/concessionarias");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListaGrupos() {
    var objEnvio = {};
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`participante/grupo`);
      setListaGrupos(response?.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListaDivisao() {
    var objEnvio = {};
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`dealer/divisao`);
      setListaDivisao(response?.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListaRegional() {
    var objEnvio = {};
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`dealer/regional`);
      setListaRegional(response?.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListaDivisao();
    fnListaRegional();
    fnListaGrupos();
    if (params.id) {
      fnConcessionariaBuscar();
    }
  }, []);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR CONCESSIONÁRIA" : "CADASTRO DE CONCESSIONÁRIA"}
      </div>
      <div className="subTituloPagina">Dados cadastrais</div>
      <div className="row">
        <div className="col-md-4 mt-3">
          <label>
            Cód. Concessionária
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              maxLength={8}
              value={concessionaria.codigoDealer}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  codigoDealer: e.target.value.replace(/\D/g, ""),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {concessionaria.codigoDealer.length < 1 ? "Inserir código" : " "}
          </div>
        </div>

        <div className="col-md-4 mt-3">
          <label>
            CNPJ<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="00.000.000/0000-00"
              disabled={params.id}
              value={Mask.cnpj(concessionaria.cnpj)}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  cnpj: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!validaCnpj(concessionaria.cnpj) ? "Inserir CNPJ valido" : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <label>
            Grupo da Concessionária
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              maxLength={64}
              value={concessionaria.grupo}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  grupo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {concessionaria?.grupo?.length < 1
              ? "Inserir Grupo da Concessionária"
              : " "}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <label>
            Razão Social<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={concessionaria.razaoSocial}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  razaoSocial: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {concessionaria.razaoSocial.length < 3
              ? "Inserir razão social"
              : " "}
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <label>
            Nome Fantasia
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={concessionaria.nomeFantasia}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  nomeFantasia: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {concessionaria.nomeFantasia.length < 3
              ? "Inserir nome fantasia"
              : " "}
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <label>
            Grupo<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={concessionaria.grupoId}
            onChange={(e) => {
              e.persist();
              setConcessionaria((prev) => ({
                ...prev,
                grupoId: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {listaGrupos?.map((e, i) => (
              <option key={i} value={e.participanteGrupoId}>
                {e.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={concessionaria.status}
            onChange={(e) => {
              e.persist();
              setConcessionaria((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value={"Ativo"}>Ativo</option>
            <option value={"Inativo"}>Inativo</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <label>
            Divisão <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={concessionaria.divisao}
            onChange={(e) => {
              e.persist();
              setConcessionaria((prev) => ({
                ...prev,
                divisao: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {listaDivisao?.map((e, i) => (
              <option key={i} value={e.divisao}>
                {e.divisao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mt-3">
          <label>
            Regional <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={concessionaria.regional}
            onChange={(e) => {
              e.persist();
              setConcessionaria((prev) => ({
                ...prev,
                regional: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {listaRegional?.map((e, i) => (
              <option key={i} value={e.regional}>
                {e.regional}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={params.id ? fnConcessionariaEditar : fnConcessionariaNovo}
            disabled={
              concessionaria.codigoDealer.length < 1 ||
              !validaCnpj(concessionaria.cnpj) ||
              concessionaria.razaoSocial.length < 3 ||
              concessionaria.nomeFantasia.length < 3
            }
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
