import React, { useEffect, useState } from "react";

import logoDev from "../../assets/images/png/logo.png";
// import LogoMenu from "../../assets/images/pngs/logDev.png";
import { BiSolidReport, BiUser } from "react-icons/bi";
import { BsChatLeft } from "react-icons/bs";
import { FaQuestionCircle, FaRegNewspaper } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { ImExit, ImStatsDots } from "react-icons/im";
import { MdAppRegistration, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./index.css";


export default function MenuLateral() {
  const [menuAbrir, setMenuAbrir] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setLarguraTela(window.innerWidth);
      setMenuAbrir(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Sidebar
        collapsed={menuAbrir}
        style={{ height: "100vh" }}
        collapsedWidth="46px"
      >
        <div
          className="d-flex justify-content-end mt-2"
          onClick={() => {
            setMenuAbrir(!menuAbrir);
          }}
        >
          {menuAbrir ? (
            <MdOutlineKeyboardDoubleArrowRight />
          ) : (
            <MdOutlineKeyboardDoubleArrowLeft />
          )}
        </div>
        <Link to={"/home"} className="d-flex justify-content-center">
          <img
            src={logoDev}
            alt="logo"
            style={{ width: "50%", minWidth: 40 }}
          />
        </Link>
        
        <Menu>
        {<MenuItem
            onClick={() => {
              navigate("/dashboard");
            }}
            icon={<ImStatsDots />}
          >
            Dashboard
          </MenuItem>}
          <SubMenu label="Gestão" icon={<BiUser />}>
            <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/administradores");
              }}
            >
              Administradores
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/participantes");
              }}
            >
              Participantes
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/concessionarias");
              }}
            >
              Concessionárias
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/veiculos");
              }}
            >
              Veículos
            </MenuItem>
          </SubMenu>

          <SubMenu label="Campanhas" icon={<MdAppRegistration />}>
            <MenuItem
              onClick={() => {
                navigate("/campanhas/gestao-de-campanhas");
              }}
            >
              Gestão de Campanhas
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/campanhas/importacao");
              }}
            >
              Importação
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/campanhas/apuracao");
              }}
            >
              Apuração
            </MenuItem>
          </SubMenu>

          <SubMenu label="Acompanhamento" icon={<FaMagnifyingGlass />}>
            <MenuItem
              onClick={() => {
                navigate("/acompanhamento/performance");
              }}
            >
              Performance
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/acompanhamento/ranking");
              }}
            >
              Ranking
            </MenuItem>
          </SubMenu>

          <SubMenu label="Comunicação" icon={<FaRegNewspaper />}>
            <MenuItem
              onClick={() => {
                navigate("/comunicacao/noticia");
              }}
            >
              Notícias
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/comunicacao/banner");
              }}
            >
             Banner
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/comunicacao/como-funciona");
              }}
            >
              Como funciona
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/comunicacao/regulamento");
              }}
            >
              Regulamento
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/comunicacao/pop-up");
              }}
            >
              Pop-up
            </MenuItem>
          </SubMenu>

          <SubMenu label="Fale Conosco" icon={<BsChatLeft />}>
            <MenuItem
              onClick={() => {
                navigate("/fale-conosco/chamado");
              }}
            >
              Chamado
            </MenuItem>
          </SubMenu>

        
            <MenuItem
                icon={<FaQuestionCircle />}
              onClick={() => {
                navigate("/faq");
              }}
            >
              FAQ
            </MenuItem>
            <MenuItem
                icon={<BiSolidReport />}
              onClick={() => {
                navigate("/relatorios");
              }}
            >
              Relatórios
            </MenuItem>
 

          <MenuItem
            onClick={() => {
              navigate("/logout");
            }}
            icon={<ImExit />}
          >
            Sair
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
}
