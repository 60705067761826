import React from "react";
import Swiper from "swiper";
import "./index.css";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";

import Logo from "../../assets/images/png/dev.png";
import { validaCpf } from "../../services/validaCpf";
import { validaEmail } from "../../services/validaEmail";
import Mask from "../../services/mask";
export default function Resetar() {
  const navigate = useNavigate();
  const [esqueciSenha, setEsqueciSenha] = useState({ userName: "" });

  async function fnResetar() {
    try {
      let response = await ApiServices.apiPost(
        "usuario/senha/resetar",
        esqueciSenha
      );

      if (response?.statusCode == 422) {
        toast.error(response.message);
        setEsqueciSenha((prev) => ({
          ...prev,
          userName: "",
        }));
      } else {
        toast.success("Reset realizado com sucesso. Verifique seu e-mail");
        navigate("/login");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }

  return (
    <>
      <div className="col-md-6 centroVertical">
        <div className="cardResetar">
          <img className="mb-3" width={"100%"} src={Logo} alt="" />

          <form autoComplete="off" className="w-100 ">
            <label>Usuário</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={
                /\d/g.test(esqueciSenha.userName[0])
                  ? Mask.cpf(esqueciSenha.userName)
                  : esqueciSenha.userName
              }
              onChange={(e) => {
                setEsqueciSenha((prev) => ({
                  ...prev,
                  userName: Mask.number(e.target.value),
                }));
              }}
            />
            <div className="avisoCampo">
              {(/\d/g.test(esqueciSenha.userName[0])
                ? !validaCpf(esqueciSenha.userName)
                : !validaEmail(esqueciSenha.userName)) &&
              esqueciSenha.userName.length >= 5
                ? "Formato inválido"
                : " "}
            </div>
          </form>

          <button
            className="btn btn-primary w-100 my-2"
            onClick={fnResetar}
            disabled={
              /\d/g.test(esqueciSenha.userName[0])
                ? !validaCpf(esqueciSenha.userName)
                : !validaEmail(esqueciSenha.userName)
            }
          >
            RESETAR SENHA
          </button>
          <button
            className="btn btn-outline-primary w-100 my-2"
            onClick={() => navigate("/")}
          >
            VOLTAR
          </button>
        </div>
      </div>
    </>
  );
}
