import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";

export default function Concessionarias() {
  const [listaAdministradores, setListaAdministradores] = useState([]);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "codigoDealer",
      header: "Codígo",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "cnpj",
      header: "CNPJ",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "grupo",
      header: "Grupo Concessionária",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size:250,
    },
    {
      accessorKey: "razaoSocial",
      header: "Razão Social",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size:250,
    },
    {
      accessorKey: "nomeFantasia",
      header: "Nome Fantasia",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size:250,
    },
    {
      accessorKey: "divisao",
      header: "Divisão",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "regionalVendas",
      header: "Regional",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      header: "Ações",
      id: "mrt-custom-actions",
      size: 90,
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate(
              "/gestao-de-usuarios/concessionarias/editar/" +
                row.original.dealerId
            );
          }}
        >
          <FaRegEdit style={{ fontSize: 20 }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnExtrairConcessionarias() {
    const parametros = {
      codigoDealer: novoFiltroColuna.codigoDealer || "",
      cnpj: novoFiltroColuna.cnpj || "",
      razaoSocial: novoFiltroColuna.razaoSocial || "",
      nomeFantasia: novoFiltroColuna.nomeFantasia || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `relatorios/dealers?${queryString}`,
        "cadastroconcessionaria"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnConcessionarias() {
    const parametros = {
      codigoDealer: novoFiltroColuna.codigoDealer || "",
      cnpj: novoFiltroColuna.cnpj || "",
      razaoSocial: novoFiltroColuna.razaoSocial || "",
      razaoSocial: novoFiltroColuna.razaoSocial || "",
      nomeFantasia: novoFiltroColuna.nomeFantasia || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`dealer?${queryString}`);

      if (response.statusCode == 200) {
        setListaAdministradores(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const newObject = {};

    filtroColuna.forEach((item) => {
      newObject[item.id] = item.value;
    });
    setNovoFiltroColuna(newObject);
     
  }, [filtroColuna]);
  useEffect(() => {
    fnConcessionarias();
  }, [novoFiltroColuna]);
  return (
    <>
      <div className="row">
        <span className="tituloPagina">CADASTRO DE CONCESSIONÁRIAS</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/concessionarias/novo");
            }}
          >
            + | Nova concessionária
          </button>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={fnExtrairConcessionarias}
          >
            Extrair concessionárias
          </button>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/concessionarias/importacao");
            }}
          >
            Importar concessionárias
          </button>
          </div>
        <div className="subTituloPagina">Concessionária cadastradas</div>

        <Tabela
          colunas={colunas}
          dados={listaAdministradores}
          setPaginacao={setPaginacao}
          paginacao={paginacao}
          filtroColuna={filtroColuna}
          setFiltroColuna={setFiltroColuna}
        />
      </div>

      {/* <div style={{ width: 2500, height: 2500, backgroundColor: "green" }}>
        ffffffffffff
      </div> */}
    </>
  );
}
