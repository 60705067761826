import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";

export default function Dashboard() {

  const navigate = useNavigate();
  const { load, setLoad } = useLoad();

  async function fnLoad() {
    
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`bi/dashboard`);

      if (response.statusCode === 200) {

        var url = response.data.url;
        let myIframe = document.getElementById("PowerBI");
        myIframe.src = url;
        
        myIframe.addEventListener('load', function() {
          myIframe.style.display = 'block';
        });

      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnRefresh() {
    
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`bi/dashboard/atualizar`);

      if (response.statusCode === 200) {
        toast.success("Atualização solicitada. Aguarde alguns minutos e recarregue a página");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }



  useEffect(() => {
    fnLoad();
  }, []);

  return (
    <>
      <div className="page-container">

      <div className="mt-5 mb-3 page-title d-flex justify-content-between">
        <div className="title">
          Dashboard
        </div>
        <div>
          <button onClick={fnRefresh} type="button" className="btn btn-primary">Atualizar</button>
        </div>
      </div>


      <div className="row mt20 page-container-box">
        <div className="col-sm-12 col-md-12">
          <div className="pageHeader noBorder">
              <iframe title="Dashboard PowerBI" id='PowerBI' height= "600" width= "100%"  frameborder="0" allowFullScreen="true"/> 
          </div>
        </div>
      </div>

      </div>
    </>
  );
}
