import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import Tabela from "../../Components/Tabela";
import moment from "moment";

export default function PerformanceNovoEditar() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const [pontosSaldo, setPontosSaldo] = useState(0);
  const [pontosCreditados, setPontosCreditados] = useState(0);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [performance, setPerformance] = useState({
    nome: "",
    cpf: "",
    cargo: "",
    concessionaria: "",
    pesquisar: "",
  });
  const [listarVendas, setListarVendas] = useState([]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "dataVarejo",
      header: "Data de venda",
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
    },
    {
      accessorKey: "participanteNome",
      header: "Vendedor",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "vin",
      header: "VIN",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "orderHmbProduct",
      header: "Produto",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "pontos",
      header: "Pontos ganhos",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    // {
    //   accessorKey: "pontos",
    //   header: "Data expiração",
    //   muiTableHeadCellProps: { align: "center" },
    //   muiTableBodyCellProps: { align: "center" },
    // },
  ];
  async function fnBuscarParticipante() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(
        `performance/detalhe?cpf=` + params.id
      );

      setPerformance((prev) => ({
        ...prev,
        nome: response.data.participanteNome,
        cpf: response.data.cpf,
        cargo: response.data.participanteCargo,
        concessionaria: response.data.dealerNome,
      }));
      fnAcessoEspelho(response.data.participanteOrigemId);
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListarVendas() {
    try {
      setLoad(true);
      const parametros = {
        q: performance.pesquisar || "",
        page: paginacao.pageIndex || "1",
        pageSize: paginacao.pageSize || "10",
        cpf: params.id || "",
      };
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `performance/venda?${queryString}`
      );
      if (response.statusCode == 200) {
        response.data.items.forEach((e) => {
          e.pontos = e.pontos?.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          });
          e.dataVarejo = moment(e.dataVarejo).format("DD/MM/YYYY");
        });

        setListarVendas(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnAcessoEspelho(id) {
    var objEnvio = { userId: id };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost("login/espelho", objEnvio);

      if (response.statusCode == 200) {
        fnPontosExtract(response.data.token);
        fnPontosBalance(response.data.token);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPontosExtract(token) {
    // token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI2NDg4RjFCQS1EQzIzLTQ3MDUtQjEyNS1FRkFDMTNDMUMxMkEiLCJ1bmlxdWVfbmFtZSI6IjA4NjAwOTA5OTAyIiwiZW1haWwiOiJhbmRlcnNvbi5sdWl6QGNhb2EuY29tLmJyIiwiUGVybWlzc2lvbiI6WyJWaW5jdWxhQ29tUm9sZUdlcmVudGUiLCJDcmlhQWx0ZXJhQXBhZ2FOb3RpY2lhIiwiQWNlc3NhUG9ydGFsIl0sIm5iZiI6MTcwNzUwNTY2MCwiZXhwIjoxNzA3NTEyODYwLCJpYXQiOjE3MDc1MDU2NjAsImlzcyI6Ik1ldVNpc3RlbWEiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdCJ9.pT8OIO3otGdk7X8fbjj4XLGndTarVOWBrGpLH5lXfQo";
    try {
      setLoad(true);
      const response = await ApiServices.apiPostCatalogo("extract", token);
      setPontosCreditados(response.creditBalance);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPontosBalance(token) {
    // token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI2NDg4RjFCQS1EQzIzLTQ3MDUtQjEyNS1FRkFDMTNDMUMxMkEiLCJ1bmlxdWVfbmFtZSI6IjA4NjAwOTA5OTAyIiwiZW1haWwiOiJhbmRlcnNvbi5sdWl6QGNhb2EuY29tLmJyIiwiUGVybWlzc2lvbiI6WyJWaW5jdWxhQ29tUm9sZUdlcmVudGUiLCJDcmlhQWx0ZXJhQXBhZ2FOb3RpY2lhIiwiQWNlc3NhUG9ydGFsIl0sIm5iZiI6MTcwNzUwNTY2MCwiZXhwIjoxNzA3NTEyODYwLCJpYXQiOjE3MDc1MDU2NjAsImlzcyI6Ik1ldVNpc3RlbWEiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdCJ9.pT8OIO3otGdk7X8fbjj4XLGndTarVOWBrGpLH5lXfQo";
    try {
      setLoad(true);
      const response = await ApiServices.apiPostCatalogo("balance", token);
      setPontosSaldo(response.balance);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnListarVendas();
  }, [paginacao, performance.pesquisar]);

  useEffect(() => {
    fnBuscarParticipante();
  }, []);
  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "PERFORMANCE" : "PERFORMANCE"}
      </div>
      <div className="subTituloPagina">Performance do participante</div>

      <div className="row">
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Pontos ganhos (total)</label>
            <input
              autoComplete="off"
              className="form-control"
              disabled
              type="text"
              value={pontosCreditados?.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
              onChange={() => {}}
            />
          </form>
        </div>
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Saldo atual</label>
            <input
              autoComplete="off"
              className="form-control"
              disabled
              type="text"
              value={pontosSaldo?.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
              onChange={() => {}}
            />
          </form>
        </div>
      </div>

      <div className="subTituloPagina">Dados do participante</div>
      <div className="row">
        {/* NOME */}
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>Nome</label>
            <input
              autoComplete="off"
              className="form-control"
              disabled
              type="text"
              value={performance.nome}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  nome: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        {/* CPF USERNAME */}
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>CPF (Username)</label>
            <input
              autoComplete="off"
              className="form-control"
              disabled
              type="text"
              placeholder="000.000.000-00"
              value={Mask.cpf(performance.cpf)}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  cpf: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
        </div>
      </div>
      <div className="row">
        {/* CONCESSIONARIA */}
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>Conssecionária</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              disabled
              value={performance.concessionaria}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  concessionaria: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        {/* CARGO */}
        <div className="col-md-6 mt-3">
          <form autoComplete="off">
            <label>Cargo</label>
            <input
              autoComplete="off"
              className="form-control"
              disabled
              type="text"
              value={performance.cargo}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  cargo:e.target.value,
                }));
              }}
            />
          </form>
        </div>
      </div>

      <div className="subTituloPagina">Histórico de Vendas</div>
      <div>Pontos ganhos</div>
      <Tabela
        colunas={colunas}
        dados={listarVendas?.items || []}
        paginacaoPelaApi={true}
        setPesquisar={(e) => {
          setPerformance((prev) => ({
            ...prev,
            pesquisar: e,
          }));
        }}
        totalLinhas={listarVendas?.totalCount}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        buscaColunas={false}
        buscaGlobal={false}
        setFiltroColuna={setFiltroColuna}
      />
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  );
}
