import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
export default function Performance() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [listarParticipantes, setListarParticipantes] = useState([]);
  const [arquivoFile, setArquivoFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [meses, setMeses] = useState([]);
  const [ano, setAno] = useState([]);
  const [performance, setPerformance] = useState({
    pesquisar: "",
    concessionariaId: "",
    cargoId: "",
    mes: "",
    ano: "",
  });

  const [listarConcessionarias, setListarConcessionarias] = useState([]);
  const [listaCargo, setListaCargo] = useState([]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "participanteNome",
      header: "Nome",
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
    },
    {
      accessorKey: "participanteCargo",
      header: "Cargo",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "dealerCodigo",
      header: "Cód. Concessionária",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "dealerNome",
      header: "Concessionária",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "pontos",
      header: "Pontos ganhos",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      header: "Ações",
      size: 90,
      id: "mrt-custom-actions",
      accessor: (row) => row,
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate("/acompanhamento/performance/editar/" + row.original.cpf);
          }}
        >
          <FaRegEdit style={{ fontSize: 20 }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];
  async function fnListarParticipantes() {
    const parametros = {
      q: performance.pesquisar || "",
      page: paginacao.pageIndex || "1",
      pageSize: paginacao.pageSize || "",
      participanteCargoId: performance.cargoId || "",
      dealerId: performance.concessionariaId || "",
      mes: performance.mes || "",
      ano: performance.ano || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`performance?${queryString}`);
      response.data.items.forEach((element) => {
        element.pontos = element.pontos.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
      });
      if (response.statusCode == 200) {
        setListarParticipantes(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarConcessionarias() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("dealer/pra-performance");

      if (response.statusCode == 200) {
        setListarConcessionarias(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarCargos() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("participante/cargo");

      if (response.statusCode == 200) {
        setListaCargo(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarMeses() {
    const parametros = {
      ano: performance.ano || "",
    };
    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`performance/mes?${queryString}`);

      if (response.statusCode == 200) {
        setMeses(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarAno() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("performance/ano");

      if (response.statusCode == 200) {
        setAno(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarArquivo(e) {
    setLoad(true);
    try {
      const file = e.target.files[0];
      const dataForm = new FormData();
      dataForm.append("file", file);
      setArquivoFile(dataForm);
      setNomeArquivo(file.name);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }
  async function fnEnviarArquivo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(
        `upload/campanha`,
        arquivoFile
      );

      setPerformance((prev) => ({
        ...prev,
        arquivoAnexado: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExtrair() {
    try {
      setLoad(true);
      const parametros = {
        q: performance.pesquisar || "",
        page: paginacao.pageIndex || "1",
        pageSize: paginacao.pageSize || "",
        participanteCargoId: performance.cargoId || "",
        dealerId: performance.concessionariaId || "",
        mes: performance.mes || "",
        ano: performance.ano || "",
      };
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `performance/exportar?${queryString}`,
        "relatorio de performace"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarConcessionarias();
    fnListarCargos();
    fnListarMeses();
    fnListarAno();
  }, []);
  useEffect(() => {
    fnListarMeses();
  }, [performance.ano]);
  useEffect(() => {
    fnListarParticipantes();
  }, [paginacao.pageSize, paginacao.pageIndex, performance.pesquisar]);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">PERFORMANCE</span>

        <div className="row">
          <div className="col-md-2 mt-3">
            <label>Concessionária</label>
            <select
              className="form-select"
              value={performance.concessionariaId}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  concessionariaId: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {listarConcessionarias.map((e, i) => (
                <option key={i} value={e.dealerId}>
                  {(e.codigoDealer || "sem código") +
                    " " +
                    (e.nome || "sem nome")}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Cargo</label>
            <select
              className="form-select"
              value={performance.cargoId}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  cargoId: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {listaCargo.map((e, i) => (
                <option key={i} value={e.participanteCargoId}>
                  {e.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Ano</label>
            <select
              className="form-select"
              value={performance.ano}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  ano: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {ano.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Mês</label>
            <select
              className="form-select"
              value={performance.mes}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  mes: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {meses.map((e, i) => (
                <option key={i} value={e.mesId}>
                  {e.descricao}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 mt-3">
            <label> </label>
            <button
              className="btn btn-primary "
              onClick={fnListarParticipantes}
            >
              Buscar
            </button>
          </div>
          <div className="col-md-2 mt-3">
            <label> </label>
            <button className="btn btn-secondary " onClick={fnExtrair}>
              Extrair
            </button>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12 mt-3 ">
            {nomeArquivo && (
              <div className="d-flex align-items-center">
                <BsFiletypeXlsx style={{ fontSize: 30 }} />
                <div className="ms-2">{nomeArquivo}</div>
              </div>
            )}
          </div>
        </div>

        <span className="subTituloPagina mt-5">Participantes</span>
      </div>
      <Tabela
        colunas={colunas}
        dados={listarParticipantes.items || []}
        paginacaoPelaApi={true}
        buscaGlobal={true}
        setPesquisar={(e) => {
          setPerformance((prev) => ({
            ...prev,
            pesquisar: e,
          }));
        }}
        totalLinhas={listarParticipantes?.totalCount}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
        buscaColunas={false}
      />
    </>
  );
}
