import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";
import moment from "moment";

export default function ComoFunciona() {
  const [listaNoticias, setListaNoticias] = useState([]);

  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "titulo",
      header: "Título",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },

    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      header: "Ações",
      id: "mrt-custom-actions",
      size: 90,
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate("/comunicacao/como-funciona/editar/" + row.original.comoFuncionaId);
          }}
        >
          <FaRegEdit style={{ fontSize: 20 }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnListaNoticias() {
    const parametros = {
      // titulo: novoFiltroColuna.titulo || "",
      // dataDe: novoFiltroColuna.dataInicio || "",
      // dataAte: novoFiltroColuna.dataFim || "",
      // status: novoFiltroColuna.status || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`como-funciona/admin?${queryString}`);

      if (response.statusCode == 200) {
        response.data.forEach((e) => {
          e.status = e.ativo ? "Ativo" : "Inativo";
          e.dataFim =
            (e.dataFim && moment(e.dataFim).format("DD/MM/YYYY")) ||
            "Indeterminado";
          e.dataInicio =
            (e.dataInicio && moment(e.dataInicio).format("DD/MM/YYYY")) ||
            "Indeterminado";
        });
        setListaNoticias(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const newObject = {};

    filtroColuna.forEach((item) => {
      newObject[item.id] = item.value;
    });
    setNovoFiltroColuna(newObject);
  }, [filtroColuna]);

  useEffect(() => {
    fnListaNoticias();
  }, [novoFiltroColuna]);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">CADASTRO DE MANUAIS</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/comunicacao/como-funciona/novo");
            }}
          >
            + | Novo manual
          </button>
        </div>
        <div className="subTituloPagina">Manuais cadastradas</div>
      </div>
      <Tabela
        colunas={colunas}
        dados={listaNoticias}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />

      {/* <div style={{ width: 2500, height: 2500, backgroundColor: "green" }}>
        ffffffffffff
      </div> */}
    </>
  );
}
