import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import { GrAttachment } from "react-icons/gr";
import { IoTrashOutline } from "react-icons/io5";

import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import Checkbox from "../../Components/Checkbox";
import moment from "moment";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default function GestaoRegulamentoNovoEditar() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [regulamento, setRegulamento] = useState({
    titulo: "",
    status: "inativo",
    mensagem: ""
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();

  async function fnRegulamentoBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`regulamento/` + params.id);
      setRegulamento((prev) => ({
        ...prev,
        titulo: response.data.titulo,
        status: response.data.status ? "ativo" : "inativo",
        mensagem: response.data.mensagem
      }))

      const contentBlock = htmlToDraft(response.data.mensagem);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  
  async function fnRegulamentoNovo() {
    try {
      var objEnvio = {
        titulo: regulamento.titulo,
        status: regulamento.status == "ativo" ? true : false,
        mensagem: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`regulamento`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Regulamento cadastrado");
        navigate("/comunicacao/regulamento");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnRegulamentoEditar() {
    var objEnvio = {
      titulo: regulamento.titulo,
      status: regulamento.status == "ativo" ? true : false,
      mensagem: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPut(
        `regulamento/` + params.id,
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success("Regulamento alterado");
        navigate("/comunicacao/regulamento");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnSalvar() {
    let response = await ApiServices.apiGet('regulamento/ativo');
    if(response?.data?.status && 
      regulamento.status == "ativo" &&
      response?.data?.regulamentoId != params.id) {
        toast.error('Já existe um Regulamento ativo.')
    } else {
      if (params.id) {
        fnRegulamentoEditar();
      } else {
        fnRegulamentoNovo();
      }
    }
  }

  useEffect(() => {
    if (params.id) {
      fnRegulamentoBuscar();
    }
  }, []);
  useEffect(() => {
  }, [regulamento]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR REGULAMENTO" : "CADASTRO DE REGULAMENTO"}
      </div>
      <div className="subTituloPagina">Configuração geral</div>

      <div className="row">
        <div className="col-md-7 mt-3">
          <label>
            Título<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              maxLength={150}
              type="text"
              value={regulamento?.titulo}
              onChange={(e) => {
                e.persist();
                setRegulamento((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {regulamento?.titulo?.length < 3 ? "Inserir título" : " "}
          </div>
        </div>
        <div className="col-md-5 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={regulamento?.status}
            onChange={(e) => {
              e.persist();
              setRegulamento((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="mt-3">
            <label>
              Mensagem
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <form autoComplete="off">
              <div className="form-control">
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(e) => {
                    setEditorState(e);
                  }}
                />
              </div>
              <div className="avisoCampo">
                {draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  .length < 9
                  ? "Inserir texto valido"
                  : " "}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12 mt-3">
          <label>Mensagem</label>
          <form autoComplete="off">
          <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(e) => {
                setEditorState(e);
              }}
            />
            <div className="avisoCampo">
                {draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  .length < 9
                  ? "Inserir texto valido"
                  : " "}
              </div>
            <textarea
              autoComplete="off"
              className="form-control"
              rows={5}
              placeholder="Descreva aqui o conteúdo da mensagem"
              type="text"
              value={regulamento?.mensagem || ""}
              onChange={(e) => {
                e.persist();
                setRegulamento((prev) => ({
                  ...prev,
                  mensagem: e.target.value,
                }));
              }}
            />
          </form>
        </div>
      </div> */}
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            disabled={regulamento?.titulo?.length < 3}
            onClick={fnSalvar}
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
