import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";
import Mask from "../../services/mask";

export default function Faq() {
  const [listaFaq, setListaFaq] = useState([]);

  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const [pesquisar, setPesquisar] = useState("");

  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "titulo",
      header: "Título",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },

    {
      accessorKey: "ordem",
      header: "Ordem",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "ativo",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Ações",
      size: 90,
      id: "mrt-custom-actions",
      accessor: (row) => row,
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate("/faq/editar/" + row.original.faqId);
          }}
        >
          <FaRegEdit style={{ fontSize: 20 }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnExtrairFaq() {
    const parametros = {
      nome: novoFiltroColuna.nome || "",
      userName: novoFiltroColuna.userName || "",
      email: novoFiltroColuna.email || "",
      status: novoFiltroColuna.status || "",
    };
    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `relatorios/administradores?${queryString}`,
        "cadastroadministradores"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnFaq() {
    const parametros = {
      q: pesquisar || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `faq/de-participante?` + queryString
      );
      response.data.forEach((e, i) =>
        e.ativo ? (e.ativo = "Ativo") : (e.ativo = "Inativo")
      );
      if (response.statusCode == 200) {
        setListaFaq(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (!pesquisar) {
      setPesquisar("");
    }
    fnFaq();
  }, [pesquisar]);

  return (
    <>
      <div className="row ">
        <span className="tituloPagina">CADASTRO DE FAQ</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/faq/novo");
            }}
          >
            + | Novo faq
          </button>
        </div>
        {/* <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={fnExtrairFaq}
          >
            Extrair faq
          </button>
        </div> */}
        <div className="subTituloPagina">Faqs cadastrados</div>
      </div>
      <Tabela
        colunas={colunas}
        dados={listaFaq}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        setPesquisar={setPesquisar}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
        buscaColunas={false}
        buscaGlobal={true}
      />

      {/* <div style={{ width: 2500, height: 2500, backgroundColor: "green" }}>
        ffffffffffff
      </div> */}
    </>
  );
}
