import React from "react";
import Swiper from "swiper";
import "./index.css";
import Logo from "../../assets/images/png/dev.png";
import Linkedin from "../../assets/images/svg/linkedin.svg";
import Instagram from "../../assets/images/svg/instagram.svg";
import Facebook from "../../assets/images/svg/facebook.svg";
import Youtube from "../../assets/images/svg/youtube.svg";
import Twitter from "../../assets/images/svg/twitter.svg";
import LogoVertem from "../../assets/images/png/logoVertem.png";
import {TextField} from "@mui/material";
import {useNavigate} from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import {useState} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {useEffect} from "react";
import {useLoad} from "../../context/Load";

export default function FaqDeslogado() {
  const navigate = useNavigate();
  const {load, setLoad} = useLoad();
  const [faqDeslogado, setFaqDeslogado] = useState([]);
  async function fnFaqDeslogado() {
    try {
      setLoad(true);

      let response = await ApiServices.apiGet("faq/de-visitante");

      setFaqDeslogado(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnFaqDeslogado();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="col-md-6 mt-5">
        <div style={{margin: "0 50px"}}>
          <img className="mb-5" width={300} src={Logo} alt="" />
          <div className="tituloPerguntas">Perguntas frequentes</div>
          {faqDeslogado.map(
            (e, i) =>
              e.ativo && (
                <div key={i} className="accordion accordion-flush" id={"accordionFlushExample" + i}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={"flush-headingOne" + i}>
                      <button
                        className="accordion-button collapsed tituloFaq"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#flush-collapseOne" + i}
                        aria-expanded="false"
                        aria-controls="flush-collapseOne">
                        {e.titulo}
                      </button>
                    </h2>
                    <div
                      id={"flush-collapseOne" + i}
                      className="accordion-collapse collapse textFaq"
                      aria-labelledby={"flush-headingOne" + i}
                      data-bs-parent={"#accordionFlushExample" + i}>
                      <div
                        className="accordion-body faqLogado"
                        dangerouslySetInnerHTML={{__html: e.texto}}
                      />
                    </div>
                  </div>
                </div>
              )
          )}
          <div>
            <button
              className="btn btn-outline-primary my-5 w-50"
              onClick={() => {
                navigate(-1);
              }}>
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
