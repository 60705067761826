import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
export default function Ranking() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [listarRanking, setListarRanking] = useState([]);
  const [arquivoFile, setArquivoFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [ranking, setRanking] = useState({
    pesquisar: "",
    grupoId: "",
    cargoId: "",
  });

  const [listarConcessionarias, setListarConcessionarias] = useState([]);
  const [listaCargo, setListaCargo] = useState([]);
  const [listaGrupo, setListaGrupo] = useState([]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "posicao",
      header: "Posicao",
      size: 70,
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
    },
    {
      accessorKey: "participanteNome",
      header: "Nome",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "participanteCargo",
      header: "Cargo",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    // {
    //   accessorKey: "dealerCodigo",
    //   header: "Cód. Concessionária",
    //   muiTableHeadCellProps: { align: "center" },
    //   muiTableBodyCellProps: { align: "center" },
    // },
    // {
    //   accessorKey: "dealerNome",
    //   header: "Concessionária",
    //   muiTableHeadCellProps: { align: "center" },
    //   muiTableBodyCellProps: { align: "center" },
    // },
    {
      accessorKey: "pontos",
      header: "Pontuação",
      size: 100,
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];
  async function fnListarRanking() {
    const parametros = {
      q: ranking.pesquisar || "",
      page: paginacao.pageIndex || "1",
      pageSize: paginacao.pageSize || "",
      participanteCargoId: ranking.cargoId || "",
      participanteGrupoId: ranking.grupoId || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`ranking?${queryString}`);
      response.data.items.forEach((element) => {
        element.pontos = element.pontos.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
      });

      if (response.statusCode == 200) {
        setListarRanking(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarGrupo() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("participante/grupo");

      if (response.statusCode == 200) {
        setListaGrupo(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarCargos() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("participante/cargo");

      if (response.statusCode == 200) {
        setListaCargo(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarArquivo(e) {
    setLoad(true);
    try {
      const file = e.target.files[0];
      const dataForm = new FormData();
      dataForm.append("file", file);
      setArquivoFile(dataForm);
      setNomeArquivo(file.name);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }
  async function fnEnviarArquivo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(
        `upload/campanha`,
        arquivoFile
      );

      setRanking((prev) => ({
        ...prev,
        arquivoAnexado: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExtrair() {
    const parametros = {
      q: ranking.pesquisar || "",
      participanteCargoId: ranking.cargoId || "",
      participanteGrupoId: ranking.grupoId || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `ranking/exportar?${queryString}`,
        "relatorio de ranking"
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnListarGrupo();
    fnListarCargos();
  }, []);

  useEffect(() => {
    if (ranking.cargoId && ranking.grupoId) {
      fnListarRanking();
    }
  }, [paginacao.pageSize, paginacao.pageIndex, ranking.pesquisar]);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">RANKING</span>

        <div className="row">
          <div className="col-md-2 mt-3">
            <label>Grupo</label>
            <select
              className="form-select"
              value={ranking.grupoId}
              onChange={(e) => {
                e.persist();
                setRanking((prev) => ({
                  ...prev,
                  grupoId: e.target.value,
                }));
              }}
            >
              <option value={""}>Selecione</option>
              {listaGrupo.map((e, i) => (
                <option key={i} value={e.participanteGrupoId}>
                  {e.nome || "sem nome"}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Cargo</label>
            <select
              className="form-select"
              value={ranking.cargoId}
              onChange={(e) => {
                e.persist();
                setRanking((prev) => ({
                  ...prev,
                  cargoId: e.target.value,
                }));
              }}
            >
              <option value={""}>Selecione</option>
              {listaCargo.map((e, i) => (
                <option key={i} value={e.participanteCargoId}>
                  {e.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label> </label>
            <button
              disabled={!ranking.cargoId || !ranking.grupoId}
              className="btn btn-primary "
              onClick={fnListarRanking}
            >
              Buscar
            </button>
          </div>
          <div className="col-md-2 mt-3">
            <label> </label>
            <button
              disabled={!ranking.cargoId || !ranking.grupoId}
              className="btn btn-secondary "
              onClick={fnExtrair}
            >
              Extrair
            </button>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12 mt-3 ">
            {nomeArquivo && (
              <div className="d-flex align-items-center">
                <BsFiletypeXlsx style={{ fontSize: 30 }} />
                <div className="ms-2">{nomeArquivo}</div>
              </div>
            )}
          </div>
        </div>

        <span className="subTituloPagina mt-5">Participantes</span>
      </div>
      <Tabela
        colunas={colunas}
        dados={listarRanking.items || []}
        paginacaoPelaApi={true}
        buscaGlobal={true}
        setPesquisar={(e) => {
          setRanking((prev) => ({
            ...prev,
            pesquisar: e,
          }));
        }}
        totalLinhas={listarRanking?.totalCount}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />
    </>
  );
}
