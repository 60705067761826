import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
import modeloRelatorio from "../../assets/Doc/Modelo Importação.xlsx";
import moment from "moment";
export default function Apuracao() {
  const [importacao, setImportacao] = useState({
    campanha: "",
    importacao: "",
    arquivoAnexado: "",
  });
  const [listaImportacoes, setListaImportacoes] = useState([]);
  const [arquivoFile, setArquivoFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [idCampanha, setIdCampanha] = useState("");
  const [pesquisar, setPesquisar] = useState("");
  const [trava, setTrava] = useState(false);
  const [listarCampanha, setListarCampanha] = useState([]);
  const [listarApuracao, setListarApuracao] = useState([]);
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "data",
      header: "Data / Hora",
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
    },
    {
      accessorKey: "participanteNome",
      header: "Usuário",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "campanhaNome",
      header: "Campanha",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "status.nome",
      header: "Status",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      id: "mrt-custom-actions",
      header: "",

      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <>
          <div className="d-flex flex-row">
            {row.original.status.apuracaoStatusId != "processando" &&
            row.original.status.apuracaoStatusId != "credito-liberado" &&
            row.original.status.apuracaoStatusId != "cancelado" ? (
              <button
                className="btn btn-danger mx-1"
                style={{ minWidth: 100 }}
                disabled={trava}
                onClick={() => {
                  setTrava(true);
                  fnBotaoCancelar(row.original.apuracaoId);
                }}
              >
                Cancelar
              </button>
            ) : (
              <div
                className="mx-1"
                style={{ width: 100, height: 40, minWidth: 100 }}
              >
                {" "}
                ⁫ 
              </div>
            )}
            {row.original.status.apuracaoStatusId != "processando" &&
            row.original.status.apuracaoStatusId != "credito-liberado" &&
            row.original.status.apuracaoStatusId != "cancelado" ? (
              <button
                className="btn btn-success mx-1"
                style={{ minWidth: 100 }}
                disabled={trava}
                onClick={() => {
                  setTrava(true);
                  fnBotaoAprovar(row.original.apuracaoId);
                }}
              >
                Aprovar
              </button>
            ) : (
              <div
                className="mx-1"
                style={{ width: 100, height: 40, minWidth: 100 }}
              >
                {" "}
                ⁫ 
              </div>
            )}
            {row.original.status.apuracaoStatusId != "processando" ? (
              <button
                className="btn btn-outline-secondary mx-1"
                style={{ minWidth: 100 }}
                disabled={trava}
                onClick={() => {
                  setTrava(true);
                  fnBotaoExportar(row.original.apuracaoId);
                }}
              >
                Exportar
              </button>
            ) : (
              <div
                className="mx-1"
                style={{ width: 100, height: 40, minWidth: 100 }}
              >
                {" "}
                ⁫ 
              </div>
            )}
            {row.original.status.apuracaoStatusId != "processando" &&
            row.original.status.apuracaoStatusId != "pendente" &&
            row.original.status.apuracaoStatusId != "cancelado" ? (
              <button
                className="btn btn-outline-secondary mx-1"
                style={{ minWidth: 100 }}
                disabled={trava}
                onClick={() => {
                  setTrava(true);
                  fnBotaoLog(row.original.apuracaoId);
                }}
              >
                Log
              </button>
            ) : (
              <div
                className="mx-1"
                style={{ width: 100, height: 40, minWidth: 100 }}
              >
                {" "}
                ⁫ 
              </div>
            )}
          </div>
        </>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
      size: 400,
    },
  ];

  async function fnBotaoCancelar(id) {
    setLoad(true);
    try {
      let response = await ApiServices.apiPost(`apuracao/${id}/cancelar`);
      if (response.statusCode == 200) {
        fnListarApuracoes();
      } else {
        toast.error(response.message);
      }
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnBotaoAprovar(id) {
    setLoad(true);
    try {
      let response = await ApiServices.apiPost(`apuracao/${id}/aprovar`);
      if (response.statusCode == 200) {
        fnListarApuracoes();
      } else {
        toast.error(response.message);
      }
      setTrava(false);
    } catch (err) {
      console.error("err", err);
      setTrava(false);
    } finally {
      setLoad(false);
    }
  }
  async function fnBotaoExportar(id) {
    setLoad(true);
    try {
      let response = await ApiServices.apiDownloadXlsxGET(
        `apuracao/${id}/exportar`,
        "apuracao"
      );
      if (response.statusCode == 200) {
        fnListarApuracoes();
      } else {
        toast.error(response.message);
      }
      setTrava(false);
    } catch (err) {
      console.error("err", err);
      setTrava(false);
    } finally {
      setLoad(false);
    }
  }
  async function fnBotaoLog(id) {
    setLoad(true);
    try {
      let response = await ApiServices.apiDownloadXlsxGET(
        `apuracao/${id}/log`,
        "log_premmiar"
      );
      if (response.statusCode == 200) {
        fnListarApuracoes();
      } else {
        toast.error(response.message);
      }
      setTrava(false);
    } catch (err) {
      console.error("err", err);
      setTrava(false);
    } finally {
      setLoad(false);
    }
  }
  async function fnExtrairParticipantes(id) {
    if (idCampanha) {
      setLoad(true);
      var objEnvio = { campanhaId: idCampanha };
      try {
        let response = await ApiServices.apiPost(`apuracao`, objEnvio);
        if (response.statusCode == 200) {
          fnListarApuracoes();
        } else {
          toast.error(response.message);
        }
        setTrava(false);
      } catch (err) {
        console.error("err", err);
        setTrava(false);
      } finally {
        setLoad(false);
      }
    }
  }
  async function fnListarApuracoes() {
    if (idCampanha) {
      setLoad(true);

      const parametros = {
        q: pesquisar || "",
        page: paginacao.pageIndex || "1",
        pageSize: paginacao.pageSize || "",
        campanhaId: idCampanha || "",
      };

      try {
        const queryString = new URLSearchParams(parametros).toString();
        let response = await ApiServices.apiGet("apuracao?" + queryString);
        response.data.items.forEach((element) => {
          element.data = moment(element.data).format("DD/MM/YYYY HH:mm:ss");
        });
        if (response.statusCode == 200) {
          setListarApuracao(response.data);
        } else {
          toast.error(response.message);
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        setLoad(false);
      }
    } else {
      setListarApuracao([]);
    }
  }
  async function fnListarCampanha() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("campanha/pra-apurar");

      if (response.statusCode == 200) {
        setListarCampanha(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarApuracoes();
  }, [paginacao.pageSize, paginacao.pageIndex, pesquisar, idCampanha]);

  useEffect(() => {
    fnListarCampanha();
  }, []);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">APURAÇÃO</span>
        <div className="row d-flex align-items-end ">
          <div className="col-md-3 mt-3">
            <form autoComplete="off">
              <label>Campanha</label>

              <select
                className="form-select"
                onChange={(e) => {
                  setIdCampanha(e.target.value);
                }}
              >
                <option value="">Selecione</option>
                {listarCampanha?.map((e, i) => (
                  <option key={i} value={e.campanhaId}>
                    {e.nome}
                  </option>
                ))}
              </select>
            </form>
          </div>

          <div className="col-md-3 mt-3">
            <button
              className="btn btn-primary "
              onClick={() => {
                fnExtrairParticipantes();
                setTrava(true);
              }}
              disabled={!idCampanha || trava}
            >
              Apurar
            </button>
          </div>
        </div>
        <div className="subTituloPagina">Apurações realizadas</div>
      </div>

      <Tabela
        colunas={colunas}
        dados={listarApuracao.items || []}
        paginacaoPelaApi={true}
        totalLinhas={listarApuracao?.totalCount}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        buscaGlobal={true}
        setPesquisar={(e) => {
          setPesquisar(e);
        }}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
        buscaColunas={false}
      />
    </>
  );
}
