import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import { validaEmail } from "../../services/validaEmail";
import { validaCpf } from "../../services/validaCpf";
import { validaSenha } from "../../services/validaSenha";

export default function AdministradoresNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [administrador, setAdministrador] = useState({
    nome: "",
    cpf: "",
    celular: "",
    email: "",
    status: "true",
    senha: "",
    confirmarSenha: "",
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();

  const userName = storage.get("USUARIO").profile.userName;

  async function fnAdministradorBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`administrador/` + params.id);
      setAdministrador((prev) => ({
        ...prev,
        nome: response.data.nome,
        celular: response.data.phoneNumber,
        email: response.data.email,
        status: response.data.status == "Ativo" ? "true" : "false",
        cpf: response.data.userName,
      }));

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnAdministradorNovo() {
    try {
      var objEnvio = {
        nome: administrador.nome,
        email: administrador.email,
        senha: administrador.senha,
        celular: administrador.celular,
        ativo: administrador.status == "true" ? true : false,
        userName: administrador.cpf,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`administrador`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Administrador cadastrado com Sucesso");
        navigate("/gestao-de-usuarios/administradores");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnAtivaInativa(status) {
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        `usuario/` +
          params.id +
          "/" +
          (status == "false" ? "inativar" : "ativar")
      );

      toast.success("Administrador alterado com sucesso");

      navigate("/gestao-de-usuarios/administradores");
      // if (response.statusCode == 200) {
      //   status == "false"
      //     ? toast.error("Administrador inativo")
      //     : toast.success("Administrador ativo");
      //   // navigate("/gestao-de-usuarios/administradores");
      // } else {
      //   toast.error(response.message);
      // }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnAdministradorEditar() {
    try {
      var objEnvio = {
        nome: administrador.nome,
        email: administrador.email,
        senha: administrador.senha,
        celular: administrador.celular,
        ativo: administrador.status ? true : false,
        userName: administrador.cpf,
      };

      setLoad(true);
      let response = await ApiServices.apiPut(
        `administrador/` + params.id,
        objEnvio
      );

      if (response.statusCode == 200) {
        fnAtivaInativa(administrador.status);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      fnAdministradorBuscar();
    }
  }, []);
  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR ADMINISTRADOR" : "CADASTRO DE ADMINISTRADOR"}
      </div>
      <div className="subTituloPagina">Dados pessoais</div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <label>
            Nome<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              disabled={params.id}
              autoComplete="off"
              className="form-control"
              type="text"
              value={administrador.nome}
              onChange={(e) => {
                e.persist();
                setAdministrador((prev) => ({
                  ...prev,
                  nome: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {administrador.nome.length < 3 ? "Inserir nome" : " "}
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <label>
            CPF (Username)
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              disabled={params.id}
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="000.000.000-00"
              value={Mask.cpf(administrador.cpf)}
              onChange={(e) => {
                e.persist();
                setAdministrador((prev) => ({
                  ...prev,
                  cpf: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!validaCpf(administrador.cpf) ? "Inserir CPF valido" : " "}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mt-3">
          <label>
            Celular<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              placeholder="(00) 00000-0000"
              value={Mask.cel(administrador.celular)}
              onChange={(e) => {
                e.persist();
                setAdministrador((prev) => ({
                  ...prev,
                  celular: Mask.unMask(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {administrador.celular.length < 11 ? "Inserir celular" : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <label>
            E-mail<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={administrador.email}
              onChange={(e) => {
                e.persist();
                setAdministrador((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!validaEmail(administrador.email) ? "Inserir e-mail valido" : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={administrador.status}
            onChange={(e) => {
              e.persist();
              setAdministrador((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value={"true"}>Ativo</option>
            <option value={"false"}>Inativo</option>
          </select>
        </div>
      </div>
      <div className="subTituloPagina">Configuração de acesso</div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <label>
            Senha
            <span style={{ color: "red", fontWeight: 900 }}>
              {params.id ? "" : "*"}
            </span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="password"
              value={administrador.senha}
              onChange={(e) => {
                e.persist();
                setAdministrador((prev) => ({
                  ...prev,
                  senha: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {params.id
              ? !validaSenha(administrador.senha) &&
                administrador.senha.length > 0
                ? "Inserir senha valida"
                : " "
              : !validaSenha(administrador.senha)
              ? "Inserir senha valida"
              : " "}
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <label>
            Confirmar Senha
            <span style={{ color: "red", fontWeight: 900 }}>
              {params.id ? "" : "*"}
            </span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="password"
              value={administrador.confirmarSenha}
              onChange={(e) => {
                e.persist();
                setAdministrador((prev) => ({
                  ...prev,
                  confirmarSenha: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            <div className="avisoCampo">
              {params.id
                ? (!validaSenha(administrador.confirmarSenha) &&
                    administrador.confirmarSenha.length > 0) ||
                  !(administrador.senha === administrador.confirmarSenha)
                  ? "Inserir confirmação de senha valida"
                  : " "
                : !validaSenha(administrador.confirmarSenha) ||
                  administrador.senha !== administrador.confirmarSenha
                ? "Inserir confirmação de senha valida"
                : " "}
            </div>
          </div>
        </div>
        <ForcaSenha
          senha={administrador.senha}
          confirmarSenha={administrador.confirmarSenha}
          setValidacao={setValidacao}
        />
      </div>
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            disabled={
              (!params.id && !validacao) ||
              (administrador.senha.length > 0 && !validacao) ||
              (administrador.confirmarSenha.length > 0 && !validacao) ||
              administrador.nome.length < 3 ||
              !validaEmail(administrador.email) ||
              !validaCpf(administrador.cpf) ||
              administrador.celular.length < 11
            }
            className="btn btn-primary w-100 "
            onClick={() => {
              if (params.id) {
                fnAdministradorEditar();
              } else {
                fnAdministradorNovo();
              }
            }}
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
