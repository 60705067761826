import React from "react";
import Swiper from "swiper";
import "./index.css";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";

import Logo from "../../assets/images/png/dev.png";
import { validaCpf } from "../../services/validaCpf";
import { validaEmail } from "../../services/validaEmail";
import Mask from "../../services/mask";
import { validaSenha } from "../../services/validaSenha";
import ForcaSenha from "../../Components/ForcaSenha";
export default function RecriarSenha() {
  const navigate = useNavigate();
  const [validacao, setValidacao] = useState(false);
  const [esqueciSenha, setEsqueciSenha] = useState({
    userName: "",
    codigo: "",
    senha: "",
    confirmarSenha: "",
  });

  async function fnResetar() {
    var objEnvio = {
      userName: esqueciSenha.userName,
      codigo: esqueciSenha.codigo,
      password: esqueciSenha.senha,
    };
    try {
      let response = await ApiServices.apiPost(
        "usuario/senha/alterar",
        objEnvio
      );

      toast.success("Senha alterada com sucesso");
      navigate("/login");
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }

  return (
    <>
      <div className="col-md-6 centroVertical">
        <div className="cardResetar">
          <img className="mb-3" width={"100%"} src={Logo} alt="" />
          <form autoComplete="off" className="w-100 ">
            <label>Usuário</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={
                /\d/g.test(esqueciSenha.userName[0])
                  ? Mask.cpf(esqueciSenha.userName)
                  : esqueciSenha.userName
              }
              onChange={(e) => {
                setEsqueciSenha((prev) => ({
                  ...prev,
                  userName: Mask.number(e.target.value),
                }));
              }}
            />
            <div className="avisoCampo">
              {(/\d/g.test(esqueciSenha.userName[0])
                ? !validaCpf(esqueciSenha.userName)
                : !validaEmail(esqueciSenha.userName)) &&
              esqueciSenha.userName.length >= 5
                ? "Formato inválido"
                : " "}
            </div>
          </form>
          <form autoComplete="off" className="w-100 ">
            <label>Código</label>
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={esqueciSenha.codigo}
              onChange={(e) => {
                setEsqueciSenha((prev) => ({
                  ...prev,
                  codigo: e.target.value,
                }));
              }}
            />
            <div className="avisoCampo">
              {esqueciSenha.codigo.length < 5 ? "Formato inválido" : " "}
            </div>
          </form>
          <div className="w-100 ">
            <div>
              <label>Nova senha</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  value={esqueciSenha.senha}
                  onChange={(e) => {
                    e.persist();
                    setEsqueciSenha((prev) => ({
                      ...prev,
                      senha: e.target.value,
                    }));
                  }}
                />
              </form>
              <div className="avisoCampo">
                {!validaSenha(esqueciSenha.senha)
                  ? "Inserir senha valida"
                  : " "}
              </div>
            </div>

            <div>
              <label>Confirmar nova senha </label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  value={esqueciSenha.confirmarSenha}
                  onChange={(e) => {
                    e.persist();
                    setEsqueciSenha((prev) => ({
                      ...prev,
                      confirmarSenha: e.target.value,
                    }));
                  }}
                />
              </form>
              <div className="avisoCampo">
                {validaSenha(esqueciSenha.confirmarSenha) &&
                esqueciSenha.senha === esqueciSenha.confirmarSenha
                  ? " "
                  : "Inserir confirmação de senha valida"}
              </div>
            </div>
            <ForcaSenha
              senha={esqueciSenha.senha}
              confirmarSenha={esqueciSenha.confirmarSenha}
              setValidacao={setValidacao}
            />
          </div>

          <button
            className="btn btn-primary w-100 my-2"
            onClick={fnResetar}
            disabled={
              (/\d/g.test(esqueciSenha.userName)
                ? !validaCpf(esqueciSenha.userName)
                : !validaEmail(esqueciSenha.userName)) ||
              esqueciSenha.codigo.length < 5 ||
              !validacao
            }
          >
            RESETAR SENHA
          </button>
          {/* <button
            className="btn btn-outline-primary w-100 my-2"
            onClick={() => navigate("/")}
          >
            VOLTAR
          </button> */}
        </div>
      </div>
    </>
  );
}
