import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import Checkbox from "../../Components/Checkbox";
import moment from "moment";

export default function NoticiasNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [verImagem, setVerImagem] = useState("");
  const [arquivoFile, setArquivoFile] = useState(null);
  const [periodoAtividade, setPeriodoAtividade] = useState([
    { periodoAtividadeId: "0", nome: "Indeterminado" },
    { periodoAtividadeId: "1", nome: "Determinado" },
  ]);
  const [visualizarNoticia, setVisualizarNoticia] = useState([]);
  const [noticia, setNoticia] = useState({
    titulo: "",
    periodoAtividade: "",
    dataInicio: "",
    dataFim: "",
    status: "ativo",
    gerente: false,
    vendedor: false,
    descricao: "",
    role: [],
    conteudo: "",
    imagemAnexada: "",
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();

  const userName = storage.get("USUARIO").profile.userName;

  async function fnRoleParticipante() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`role/participante`);

      response.data.forEach((e) => {
        e.status = true;
      });
      setVisualizarNoticia(response.data);
      if (params.id) {
        fnNoticiasBuscar(response.data);
      }
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnNoticiasBuscar(listaVisualizarNoticia) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`noticia/` + params.id);
      setNoticia((prev) => ({
        ...prev,
        titulo: response.data.titulo,
        celular: response.data.phoneNumber,
        email: response.data.email,
        status: response.data.ativo ? "ativo" : "inativo",
        dataInicio: moment(response.data.dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(response.data.dataFim).format("YYYY-MM-DD"),
        descricao: response.data.descricao,
        conteudo: response.data.conteudo,
        periodoAtividade: String(response.data.periodo),
      }));
      var temp = [];
      listaVisualizarNoticia.map((e, i) => {
        let foundMatch = false;

        if (response.data.roles?.length > 0) {
          response.data.roles?.map((ele, index) => {
            if (e.roleId === ele.roleId) {
              e.status = true;
              foundMatch = true;
            }
          });
        }

        if (!foundMatch) {
          e.status = false;
        }

        temp.push(e);
      });

      setVisualizarNoticia(temp);
      setVerImagem(response.data.urlImagem);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnNoticiasNovo() {
    try {
      var objEnvio = {
        titulo: noticia.titulo,
        periodoIndeterminado: noticia.periodoAtividade,
        dataInicio: noticia.dataInicio || null,
        dataFim: noticia.dataFim || null,
        ativo: noticia.status == "ativo" ? true : false,
        roles: noticia.role,
        descricao: noticia.descricao,
        conteudo: noticia.conteudo,
        urlImagem: noticia.imagemAnexada,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`noticia`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Notícia cadastrada");
        navigate("/comunicacao/noticia");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnNoticiasEditar() {
    var objEnvio = {
      titulo: noticia.titulo,
      periodoIndeterminado: noticia.periodoAtividade,
      dataInicio: noticia.dataInicio || null,
      dataFim: noticia.dataFim || null,
      ativo: noticia.status == "ativo" ? true : false,
      roles: noticia.role,
      descricao: noticia.descricao,
      conteudo: noticia.conteudo,
      urlImagem: noticia.imagemAnexada || verImagem,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPut(`noticia/` + params.id, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Notícia alterada");
        navigate("/comunicacao/noticia");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarImagem(e) {
    try {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            const larguraImagem = image.width;
            const alturaImagem = image.height;
            if (image.width !== 360 && image.height !== 360) {
              toast.warn("Imagem deve ter 360x360px");
            } else {
              setVerImagem(e.target.result);
              setLoad(false);

              const dataForm = new FormData();
              dataForm.append("file", file);
              setArquivoFile(dataForm);
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
      }
    } catch (err) {
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }
  async function fnEnviarImagemApi() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(`upload/noticia`, arquivoFile);

      setNoticia((prev) => ({
        ...prev,
        imagemAnexada: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (noticia.imagemAnexada != "") {
      params.id ? fnNoticiasEditar() : fnNoticiasNovo();
    }
  }, [noticia.imagemAnexada]);

  useEffect(() => {
    fnRoleParticipante();
  }, []);

  useEffect(() => {
    var temp = [];
    visualizarNoticia.map((e, i) => {
      if (e.status === true) {
        temp.push({
          roleId: e.roleId,
        });
      }
    });
    setNoticia((prev) => ({
      ...prev,
      role: temp,
    }));
  }, [visualizarNoticia]);

  useEffect(() => {
    if (noticia.periodoAtividade == "0") {
      setNoticia((prev) => ({ ...prev, dataInicio: "", dataFim: "" }));
    }
  }, [noticia.periodoAtividade]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR NOTÍCIA" : "CADASTRO DE NOTÍCIA"}
      </div>
      <div className="subTituloPagina">Configuração geral</div>

      <div className="row">
        <div className="col-md-2 mt-3">
          <label>
            Título<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={noticia.titulo}
              onChange={(e) => {
                e.persist();
                setNoticia((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {noticia.titulo.length < 3 ? "Inserir título" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Tipo<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={noticia.periodoAtividade}
            onChange={(e) => {
              e.persist();
              setNoticia((prev) => ({
                ...prev,
                periodoAtividade: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {periodoAtividade.map((e, i) => (
              <option value={e.periodoAtividadeId} key={i}>
                {e.nome}
              </option>
            ))}
          </select>
          <div className="avisoCampo">
            {!noticia.periodoAtividade ? "Inserir tipo" : " "}
          </div>
        </div>

        <div className="col-md-2 mt-3">
          <label>
            Início<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="date"
              value={noticia.dataInicio || ""}
              disabled={noticia.periodoAtividade === "0"}
              onChange={(e) => {
                e.persist();
                setNoticia((prev) => ({
                  ...prev,
                  dataInicio: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!noticia.dataInicio && noticia.periodoAtividade != "0"
              ? "Inserir data"
              : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Término<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="date"
              value={noticia.dataFim || ""}
              disabled={noticia.periodoAtividade === "0"}
              onChange={(e) => {
                e.persist();
                setNoticia((prev) => ({
                  ...prev,
                  dataFim: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!noticia.dataFim && noticia.periodoAtividade != "0"
              ? "Inserir data"
              : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={noticia.status}
            onChange={(e) => {
              e.persist();
              setNoticia((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
        <div className="col-md-2 mt-3">
          <div>
            <label>
              Visualizar a notícia
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <button
              className=" form-control d-flex justify-content-between w-100 "
              data-bs-toggle="dropdown"
            >
              <div className=" tresPontinhos d-flex" style={{ width: "80%" }}>
                {visualizarNoticia.reduce(
                  (count, item) => count + (item.status === true ? 1 : 0),
                  0
                )}
                 Selecionados
              </div>
              <div>
                <svg
                  fill="#000000"
                  height="10px"
                  width="10px"
                  viewBox="0 0 330 330"
                >
                  <path
                    id="XMLID_225_"
                    d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                    stroke="#000000"
                    strokeWidth="10"
                  />
                </svg>
              </div>
            </button>
            <div className="dropdown-menu">
              {visualizarNoticia.map((option, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisualizarNoticia((prev) =>
                      prev.map((item, i) =>
                        i === index ? { ...item, status: !item.status } : item
                      )
                    );
                  }}
                >
                  <div className="m-1 ms-3  ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={option.status}
                      onChange={() => {}}
                    />
                  </div>
                  <label className="m-1 fs-6">
                    {option.displayName}
                    <span style={{ color: "red", fontWeight: 900 }}>*</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="avisoCampo">
            {!noticia.role[0] ? "Selecione um perfil" : " "}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-3">
          <label>
            Descrição<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={noticia.descricao}
              onChange={(e) => {
                e.persist();
                setNoticia((prev) => ({
                  ...prev,
                  descricao: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {noticia.descricao.length < 3 ? "Inserir descrição" : " "}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-3">
          <label>
            Conteúdo<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <textarea
              autoComplete="off"
              className="form-control"
              rows={5}
              placeholder="Descreva aqui o conteúdo da notícia"
              type="text"
              value={noticia.conteudo || ''}
              onChange={(e) => {
                e.persist();
                setNoticia((prev) => ({
                  ...prev,
                  conteudo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {noticia.conteudo?.length < 3 ? "Inserir descrição" : " "}
          </div>
        </div>
      </div>
      <div>
        {verImagem ? (
          <button
            className="btn btn-danger my-3"
            style={{ width: 360 }}
            onClick={() => {
              setVerImagem("");
              setArquivoFile(null);
              document.querySelector(".inputUpload").value = "";
            }}
          >
            Excluir arte da notícia
          </button>
        ) : (
          <button
            className="btn btn-primary my-3"
            style={{ width: 360 }}
            onClick={() => {
              document.querySelector(".inputUpload").click();
            }}
          >
            Importar arte da notícia
          </button>
        )}
        <input
          className="d-none inputUpload"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={fnCarregarImagem}
        />
        <div style={{ fontSize: 12 }}>Formatos aceitos: jpeg, jpg, png</div>
        <div style={{ fontSize: 12 }}>Tamanho recomendado: 360 x 360 px</div>
        <div
          className="border"
          style={{ width: 360, height: 360, overflow: "hidden" }}
        >
          <img src={verImagem} alt="" />
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={fnEnviarImagemApi}
            disabled={
              noticia.titulo.length < 3 ||
              noticia.periodoAtividade == "" ||
              (!noticia.dataFim && noticia.periodoAtividade != "0") ||
              (!noticia.dataInicio && noticia.periodoAtividade != "0") ||
              !noticia.role[0] ||
              noticia.descricao.length < 3 ||
              !verImagem
            }
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
