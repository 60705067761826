import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import { GrAttachment } from "react-icons/gr";
import { IoTrashOutline } from "react-icons/io5";

import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import Checkbox from "../../Components/Checkbox";
import moment from "moment";

export default function ComoFuncionaNovoEditar() {
  const [validacao, setValidacao] = useState(false);
  const [verImagem, setVerImagem] = useState("");
  const [arquivoFile, setArquivoFile] = useState(null);
  const [manual, setManual] = useState({
    titulo: "",
    status: "ativo",
    conteudo: "",
    imagemAnexada: "",
    pdfAnexado: "",
    nomeArquivo: "",
  });
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();

  async function fnManualBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`como-funciona/` + params.id);
      setManual((prev) => ({
        ...prev,
        titulo: response.data.titulo,
        status: response.data.ativo ? "ativo" : "inativo",
        descricao: response.data.descricao,
        conteudo: response.data.conteudo,
        nomeArquivo: response.data.nomeArquivo,
      }));
      setVerImagem(response.data.urlArquivo);
      setArquivoFile(response.data.urlArquivo);
      console.log("response", response);
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnManualNovo() {
    try {
      var objEnvio = {
        titulo: manual.titulo,
        ativo: manual.status == "ativo" ? true : false,
        conteudo: manual.conteudo,
        urlArquivo: manual.imagemAnexada,
        nomeArquivo: manual.nomeArquivo,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`como-funciona`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Notícia cadastrada");
        navigate("/comunicacao/como-funciona");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnManualEditar() {
    var objEnvio = {
      titulo: manual.titulo,
      ativo: manual.status == "ativo" ? true : false,
      conteudo: manual.conteudo,
      urlArquivo: manual.imagemAnexada || verImagem,
      nomeArquivo: manual.nomeArquivo,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPut(
        `como-funciona/` + params.id,
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success("Notícia alterada");
        navigate("/comunicacao/como-funciona");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarImagem(e) {
    try {
      const file = e.target.files[0];
      setManual((prev) => ({ ...prev, nomeArquivo: file.name }));

      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            if (image.width !== 360 && image.height !== 360) {
              toast.warn("Imagem deve ter 360x360px");
            } else {
              setVerImagem(e.target.result);
              setLoad(false);

              const dataForm = new FormData();
              dataForm.append("file", file);
              setArquivoFile(dataForm);
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
      }
    } catch (err) {
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }

  async function fnCarregarPDF(e) {
    try {
      const file = e.target.files[0];
      setManual((prev) => ({ ...prev, nomeArquivo: file.name }));

      if (file) {
        if (file.type !== "application/pdf") {
          toast.warn("Por favor, selecione um arquivo PDF.");
          return;
        }

        if (file.size > 10 * 1024 * 1024) {
          toast.warn("O arquivo PDF deve ter menos de 10MB.");
          return;
        }

        const reader = new FileReader();

        reader.onload = function (e) {
          setVerImagem(e.target.result);
          setArquivoFile(file);
        };

        reader.readAsDataURL(file);
      }
    } catch (err) {
      console.error("Erro ao carregar PDF:", err);
    }
  }

  async function fnEnviarImagemApi() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUploadPDF(
        `upload/manual`,
        arquivoFile
      );

      setManual((prev) => ({
        ...prev,
        imagemAnexada: response.data,
        pdfAnexado: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (params.id) {
      fnManualBuscar();
    }
  }, []);
  useEffect(() => {
    if (manual.imagemAnexada != "") {
      params.id ? fnManualEditar() : fnManualNovo();
    }
  }, [manual.imagemAnexada, manual.pdfAnexado]);
  useEffect(() => {
    console.log("manual", manual);
  }, [manual]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR MANUAL" : "CADASTRO DE MANUAL"}
      </div>
      <div className="subTituloPagina">Configuração geral</div>

      <div className="row">
        <div className="col-md-7 mt-3">
          <label>
            Título<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="form-control"
              type="text"
              value={manual?.titulo}
              onChange={(e) => {
                e.persist();
                setManual((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {manual?.titulo?.length < 3 ? "Inserir título" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="form-select"
            value={manual?.status}
            onChange={(e) => {
              e.persist();
              setManual((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
        <div className="col-md-3 mt-3">
          <label>
            {manual.nomeArquivo
              ? "Anexado: " + manual.nomeArquivo
              : "Escolher arquivo"}
          </label>
          {manual.nomeArquivo ? (
            <button
              className="btn btn-danger w-100"
              onClick={() => {
                setVerImagem("");
                setArquivoFile(null);
                setManual((prev) => ({ ...prev, nomeArquivo: "" }));
                document.querySelector(".inputUpload").value = "";
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <IoTrashOutline style={{ marginTop: -5, marginRight: 10 }} />{" "}
                <div>Excluir PDF</div>
              </div>
            </button>
          ) : (
            <button
              className="btn btn-primary w-100"
              onClick={() => {
                document.querySelector(".inputUpload").click();
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <GrAttachment style={{ marginTop: -1, marginRight: 10 }} />

                <div> Anexar PDF</div>
              </div>
            </button>
          )}
          <input
            className="d-none inputUpload"
            type="file"
            accept=".pdf"
            onChange={fnCarregarPDF}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-3">
          <label>Conteúdo</label>
          <form autoComplete="off">
            <textarea
              autoComplete="off"
              className="form-control"
              rows={5}
              placeholder="Descreva aqui o conteúdo da notícia"
              type="text"
              value={manual?.conteudo || ""}
              onChange={(e) => {
                e.persist();
                setManual((prev) => ({
                  ...prev,
                  conteudo: e.target.value,
                }));
              }}
            />
          </form>
        </div>
      </div>
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-outline-primary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={fnEnviarImagemApi}
            disabled={manual?.titulo?.length < 3 || !verImagem}
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
